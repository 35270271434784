import React, { Component } from 'react';

import { connect } from 'react-redux';
//import { updateProfileData } from '../../reducers/profileReducer';
import './Header.scss';
import FvLogo from '../../images/fvcloud-logo-sm.png';
import Coins from '../../images/coins.png';

import { withStyles } from '@material-ui/core/styles';
import { toggleMenu } from '../../actions/menu';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import ModelIcon from '@material-ui/icons/BlurLinear';

import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';

//import UserBlock from '../UserBlock';
let rmLogo = require('../../images/fvhub-logo.png');
let iLogo = require('../../images/fv-logo-flat-icon-sm.png');

const drawerWidth = 240;
const FV_URL = process.env.REACT_APP_FV_URL;
const FV_CLOUD_URL = process.env.REACT_APP_CLOUD_DOMAIN;

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  }
});

class Header extends Component {
  constructor() {
    super();
    this.state = {
      jobs: [],
      anchorEl: null,
      userTokens: null,
      open: true,
      accessToken: localStorage.getItem('access_token'),
      usrImg: '',
      usrName: '-',
      usrId: '-',
      menuOpen: false
    };
    this.editRef = React.createRef();
  }

  componentDidMount() {
    this.userAlerts();
    this.userInfo();
    this.timer = setInterval(() => {
      this.userAlerts();
      this.userInfo();
    }, 3000);
  }

  userAlerts(){
    const access_token = localStorage.getItem('access_token');
    if (access_token){
      fetch('/api/alerts/settings',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('access_token'),
            'Content-type': 'application/json'
        }
      }).then(res=>{
          return res.json();
      }).then(data=>{
          if (data){
              this.setState({alertSettings: data});
          }else{
              //init alert settings
              this.initAlertSettings();
          }
      }).catch(error=>{
          console.log(error);
      })
    }
  }

  userInfo(){
    const access_token = localStorage.getItem('access_token');
    const nickname     = localStorage.getItem('nickname');
    const avatar       = localStorage.getItem('avatar');
    if (nickname && avatar){
      this.setState({usrImg: avatar, usrName: nickname});
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false, menuAnchor: null })
  }

  login = () => {
    this.props.auth0.login();
  }

  logout = () => {
    this.props.auth0.logout(this.props.auth0);
    this.props.enqueueSnackbar('Successfully logged out', { variant: 'success' });
  }
  
  signUp = () => {
    window.open(FV_URL+'/wp-login.php', '_blank');
  }

  handleModelCreate = () => {
    window.open(FV_CLOUD_URL+'/projects/list', '_blank');
  }

  handleTokenPurchase = () => {
    window.open(FV_URL+'/product-category/token-refills/', '_blank');
  }


  openUserMenu = () => {
    if(!this.state.menuOpen){
      const menuAnchor = document.getElementById('userAvatar');
      this.setState({menuOpen: true, menuAnchor });
    }
  } 

  renderLogin() {
    const { classes }     = this.props;
    const {alertSettings} = this.state;
    const hasAlerts = alertSettings && !alertSettings.alerts_seen ? ' ' : null;
    if (!localStorage.getItem('isLoggedIn')) {
      return (
        <div>
          <Button size="large" variant="contained" color="primary" onClick={this.login} style={{marginRight: '5px'}}>
            Login
          </Button>
          <Button size="large" variant="outlined" color="primary" onClick={this.signUp}>
            Sign Up
          </Button>
        </div>
      );
    } else {
      const user_id = localStorage.getItem('user_id');
      return (
        <div>
          <IconButton onClick={()=> window.location = `/user/${user_id}?tab=2`} color="inherit" style={{marginRight: '10px'}} >
            <Badge badgeContent={hasAlerts}  color="primary" overlap="circle">
              <NotificationsIcon style={{color:'#212121'}}/>
            </Badge>
          </IconButton>
          <IconButton 
            id="userAvatar"
            onClick={()=>window.location = '/user/'+user_id} style={{marginRight: '10px'}} 
            onMouseOver={this.openUserMenu}
          >
            <Avatar alt={this.state.usrName} src={this.state.usrImg} style={{width: '55px', height: '55px'}}/>
          </IconButton>
          <Button size="large" variant="outlined" color="primary" onClick={this.logout}>
            Logout
          </Button>
          <Popper open={this.state.menuOpen} anchorEl={this.state.menuAnchor} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'center bottom' }}
              >
                <Paper onMouseLeave={this.handleClose}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem onClick={this.handleModelCreate}>
                        <img src={FvLogo} style={{width: '180px'}} className="user-menu-icon"/>
                      </MenuItem>
                      <MenuItem onClick={this.handleTokenPurchase}>
                        <img src={Coins} style={{width: '45px'}} className="user-menu-icon"/>
                        Purchase Tokens
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      );
    }
  }


  handleDrawerOpen = () => {
    this.props.toggleMenu();
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{ background: 'white', padding: '2px', boxShadow: '0px -1px 3px black' }}>
          <Toolbar style={{minHeight: '70px'}}>
            <img src={rmLogo} style={{ width: '255px', background: 'none' }} className="logo f-logo" alt='logo' onClick={()=>window.location='/'} />
            <img src={iLogo} style={{ width: '55px', background: 'none' }} className="logo i-logo" alt='logo' onClick={()=>window.location='/'} />
            <Typography variant="h6" className={classes.grow}>
            </Typography>
            {this.renderLogin()}
            <Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
              {this.state.jobs.map((row,i) => {
                return (
                  <MenuItem key={i} onClick={this.handleClose}>{row.type + ' - ' + new Date(row.start_time).toString()}</MenuItem>
                );
              })}
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMenuOpen: state.menu.isOpen
  };
};

export default withSnackbar((connect(mapStateToProps, { toggleMenu })(withStyles(styles)(Header))));
