export const createKeywords = name => {
    const arrName = [];
    let curName = '';
    name.split('').forEach(letter => {
      curName += letter;
      arrName.push(curName);
    });
    return arrName;
}

export const generateKeywords = names => {
    const [first, middle, last, sfx] = names;
    const suffix = sfx.length > 0 ? ` ${sfx}.` : '';
    const keywordNameWidthoutMiddleName = createKeywords(`${first} ${last}${suffix}`);
    const keywordFullName = createKeywords(`${first} ${middle} ${last}${suffix}`);
    const keywordLastNameFirst = createKeywords(`${last}, ${first} ${middle}${suffix}`);
    const middleInitial = middle.length > 0 ? ` ${middle[0]}.` : '';
    const keywordFullNameMiddleInitial = createKeywords(`${first}${middleInitial} ${last}${suffix}`);
    const keywordLastNameFirstMiddleInitial = createKeywords(`${last}, ${first}${middleInitial}${suffix}`);
    return [
      ...new Set([
        '',
        ...keywordFullName,
        ...keywordLastNameFirst,
        ...keywordFullNameMiddleInitial,
        ...keywordLastNameFirstMiddleInitial,
        ...keywordNameWidthoutMiddleName
      ])
    ];
}

const messageFormatter = (model, context='', type) => {
    const model_name = model.model_name;
    const messages = {
        'new_rating': `A user has given model: ${model_name} a ${context} star rating`,
        'new_purchase': `A user has purchased model: ${model_name}`,
        'model_download': `A user has downloaded model: ${model_name}`,
        'model_transfer': `A user has transfered model: ${model_name}`
    }
    return messages[type]
}

export const send_notification = (type, user_id, context, model) => {
    const message = messageFormatter(model, context, type);
    const alert = {
        'type': type,
        'message': message,
        'user_id': user_id
    }
    fetch('/api/alerts/',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('access_token')
        },
        body: JSON.stringify(alert)
    }).then(res=>{
        return res.json()
    }).then(data=>{
        return data;
    }).catch(error=>{
        console.log(error);
    })
}