// constants
export const SET_MENU_OPEN = 'menu:SET_MENU_OPEN';

export const MEDIA_OPEN = 'MEDIA_OPEN';
export const MEDIA_CLOSE = 'MEDIA_CLOSE';
export const UPDATE_ACTIVE = 'UPDATE_ACTIVE';
export const TAG_MENU_OPEN = 'TAG_MENU_OPEN';
export const CURSOR_MENU_OPEN = 'CURSOR_MENU_OPEN';
export const TOGGLE_BINDS_MENU = 'TOGGLE_BINDS_MENU';

export const MENU_INIT = {isOpen: true, showTagForm : false, showingMediaBrower:false, myTraining:{}, activePhoto:{}, tagMenuOpen: false, cursorMenuOpen: false, toggleBindsMenu: false };

// actions
export function closeMediaBrowser() {
  return { type : MEDIA_CLOSE }
}

export function openMediaBrowser(myTraining, handleImageUpload) {
  return { type : MEDIA_OPEN, myTraining, handleImageUpload }
}

export function toggleMenu() {
  return { type: SET_MENU_OPEN, payload: {} };
}

export function updateActive(path) {
  return { type: UPDATE_ACTIVE, path }
}

export function openTagMenu() {
  return {type: TAG_MENU_OPEN }
}

export function toggleCursorMenu() {
  return {type: CURSOR_MENU_OPEN }
}

export function toggleBindsMenu() {
  return {type: TOGGLE_BINDS_MENU}
}
