import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CardImage from './CardImage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import * as I from '../constants/Industries';

import _ from 'lodash';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: '6px',
      background: 'rgba(0,0,0,.04)',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '80%',
      maxWidth: '465px'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: 200,
        maxWidth: 200,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '200px',
    },
    chip: {
        margin: 2,
        background: 'white',
        boxShadow: '0px 1px 2px rgba(0,0,0,.5)'
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    inputInput: {
      padding: '10px',
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100% ',
      fontSize: '1.5em',
      height: '44px',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
});

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

class ModelList extends React.Component {
    constructor() {
        super();
        this.state = {
            catFilter: '',
            langFilter: 'English',
            pubFilter: '',
            typeFilter: 'All',
            models: [],
            filteredModels: [],
            lastModel: null,
            loading: false,
            rowsPerPage: 20,
            sortKey: 'updated',
            sortKeys:       ['created', 'rating', 'model_name', 'downloads', 'verified', 'price', 'updated'],
            categories:     I.industries,
            languages:      ['English'],
            publishers:     [],
            types:          ['TF', 'TF Lite'],
            filter_categories: [],
            filter_languages: [],
            filter_publishers: [],
            filter_types: [],
            loadMore: false,
            topKeywords: [],
            badgeInfoOpen: false
        };
    }

    componentDidMount(){
        // window.addEventListener("scroll", this.lastShowing, false);
        this.getModels();
        this.getTopKeywords();
    }

    getModels(sortSelect=null){
        let {sortKey} = this.state;
        if (sortSelect) sortKey = sortSelect;
        const sort_by = '?sort_by='+sortKey;
        fetch('/api/models/-1/'+this.state.rowsPerPage+sort_by,{
            method: 'GET',
            headers: {
                'Authorization': '',
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            if (data.length == this.state.rowsPerPage)
                this.state.loadMore = true;

            const filteredModels      = await this.filterModels(data);
            this.setState({
                lastModel: data[data.length-1].id, 
                models: data, 
                filteredModels,
                loadMore: this.state.loadMore
            });
        }).catch(error=>{
            console.log(error);
        })
    }

    getTopKeywords(sortSelect=null){
        let {sortKey} = this.state;
        if (sortSelect) sortKey = sortSelect;
        const sort_by = '?sort_by='+sortKey;
        fetch('/api/keywords/5'+sort_by,{
            method: 'GET',
            headers: {
                'Authorization': '',
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            this.setState({topKeywords: data});
        }).catch(error=>{
            console.log(error);
        })      
    }

    lastShowing = () =>{
        const page       = document.getElementById("main");
        const pageBounds = page.getBoundingClientRect();
        if(this.state.lastModel){
            if ((window.scrollY + window.innerHeight) > pageBounds.height){
                this.fetchMore();
            }
        }
    }

    fetchMore(){
        const {models,sortKey} = this.state;
        if (models.length<=0||!this.state.loadMore) return;

        this.setState({loading: true});
        const nextRowId = models[models.length-1].id
        const sort_by   = '?sort_by='+sortKey;

        fetch('/api/models/'+nextRowId+'/'+this.state.rowsPerPage+sort_by,{
            method: 'GET',
            headers: {
                'Authorization': '',
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async (data) =>{
            if (data.length < this.state.rowsPerPage)
                this.state.loadMore = false;

            if (data.length>0){
                const filtered       = await this.filterModels(data);
                const filteredModels = _.concat(this.state.filteredModels, filtered);
                const model_list     = _.concat(models, data);
                this.setState({
                    models: model_list, 
                    lastModel: model_list[model_list.length-1].id, 
                    loading: false, 
                    filteredModels,
                    loadMore: this.state.loadMore
                });
            }else{
                this.setState({loadMore: false,loading: false})
            }

        }).catch(error=>{
            console.log(error);
            this.setState({loading: false});
        })   
    }

    handleSortByChange(event){
        const sortKey = event.target.value;
        this.setState({sortKey});
        this.getModels(sortKey);
    }

    openVerifiedInfo=(e)=>{
        e.stopPropagation(); 
        this.setState({badgeInfoOpen: true});
    }

    async filterChange(filterKey, e){
        const filterValues = e.target.value;
        await this.setState({['filter_'+filterKey]: filterValues});
        const models = await this.filterModels();
        this.setState({filteredModels: models});
    }

    filterModels(models=null){
        let publishers = {}
        const filters  = {
            'category':'filter_categories', 
            'language':'filter_languages', 
            'publisher':'filter_publishers', 
            'types':'filter_types'
        };

        if (!models)
            models = this.state.models;
        
        let all_models = [];

        models.map(model=>{
            //Loop over filters
            let hasFilter    = false;
            let sumFilters   = 0;
            let hasFilters   = [];

            if (model.publisher!=''&&!(model.publisher in publishers)){
                publishers[model.publisher]=true;
            }
            Object.keys(filters).map(key=>{
                const stateKey  = filters[key];
                
                if (key in model){
                    //check to see model has set filters - 

                    const arg          = model[key];
                    const filtersCount = this.state[stateKey].length;
                    sumFilters+=filtersCount;
                    if (filtersCount > 0){
                        //if model contains filter - include the model in results
                        if (Array.isArray(arg)){
                            this.state[stateKey].map(filterKey =>{
                                if (arg.includes(filterKey)){
                                    hasFilter = true;
                                    hasFilters.push(true);
                                }else{
                                    hasFilters.push(false);
                                }
                            })
    
                        }else if (typeof arg == 'string'){
                            if (this.state[stateKey].includes(arg)){
                                hasFilter = true;
                                hasFilters.push(true)
                            }else{
                                hasFilters.push(false)
                            }

                        }

                    }
                }
            })

            const showModel = hasFilters.every((val)=> val == true)
            if (showModel||sumFilters==0)
                all_models.push(model);
        });
        
        this.setState({publishers: Object.keys(publishers)});
        return all_models;
    }

    search(e){
        let {sortKey} = this.state;
        const sort_by = '?sort_by='+sortKey;
        let keyword   = e.target.value;

        if (!keyword) keyword = '_';
        fetch('/api/models/search/'+keyword+'/'+this.state.rowsPerPage+sort_by,{
            method: 'GET',
            headers: {
                'Authorization': '',
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            if (data.length>0){
                const filteredModels = await this.filterModels(data);
                this.setState({models: data, lastModel: data[data.length-1].id, filteredModels});
            }else{
                this.setState({models: [], filteredModels: []});
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    searchByKeyword(keyword){
        const e = {target:{value: keyword}};
        this.search(e);
    }

    renderModels(){
        if (this.state.models.length > 0){
            return(
                this.state.filteredModels.map((model, i)=>{
                    return (
                        <CardImage
                            id={model.id}
                            title={model.model_name}
                            model={model}
                            key={i}
                            image={model.thumbnail_path}
                            rating={model.rating}
                            ratingCount={model.rating_count}
                            downloads={model.downloads}
                            publisher={model.publisher}
                            dialogWindow={this.openVerifiedInfo}
                            className="recent-project-card"
                        />
                    )
                })
            )
        }else{
            return (
                Array(this.state.rowsPerPage).fill().map((i)=>{
                    return (
                        <div key={i} style={{width: '350px', margin: '5px'}}>
                            <Skeleton animation="wave" style={{height: '200px'}}/>
                            <Skeleton animation={false} />
                            <Skeleton animation={false} style={{width: '80%'}}/>
                        </div>
                    )
                })
            )
        }
    }

    render (){
        const { classes } = this.props;

        return (
            <div className="page-container">
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} sm={3} md={4} lg={4}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                            <SearchIcon style={{fontSize: '2em'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={(e)=>this.search(e)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={9} md={8} lg={8}>
                        <div className="filters-lbl">Top Keywords: </div>
                        <div className={classes.chips, ' top-keywords'} >
                            {this.state.topKeywords.map((value) => {
                                const keyword = value.keyword;
                                return(
                                    <Chip 
                                        id={keyword}
                                        key={keyword} 
                                        onClick={()=>this.searchByKeyword(keyword)} 
                                        label={keyword} 
                                        className={classes.chip} 
                                    />
                                )
                            })}
                        </div>
                    </Grid>
                </Grid>
                <div className="filter-container">
                    <div className="filters-lbl">Filters: </div>
                    <div className="filter-item">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mutiple-chip-label">Categories</InputLabel>
                            <Select
                                multiple
                                value={this.state.filter_categories}
                                onChange={(e)=>this.filterChange('categories',e)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips} >
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {this.state.categories.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="filter-item">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mutiple-chip-label">Language</InputLabel>
                            <Select
                                multiple
                                value={this.state.filter_languages}
                                onChange={(e)=>this.filterChange('languages',e)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {this.state.languages.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="filter-item">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mutiple-chip-label">Publisher</InputLabel>
                            <Select
                                multiple
                                value={this.state.filter_publishers}
                                onChange={(e)=>this.filterChange('publishers',e)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {this.state.publishers.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="filter-item">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mutiple-chip-label">Type</InputLabel>
                            <Select
                                multiple
                                value={this.state.filter_types}
                                onChange={(e)=>this.filterChange('types',e)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {this.state.types.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="content-sort-container">
                    <div className="item-count">
                        <span className="filters-lbl">Showing: </span>
                        <span>{this.state.filteredModels.length}</span>
                    </div>
                    <div className="sort-by">
                        <span style={{width: '115px', color: 'rgb(97, 97, 97)'}}>Sort By: </span>
                        <TextField
                            select
                            fullWidth
                            className="filter-select"
                            onChange={(event) => this.handleSortByChange(event)}
                            margin="normal"
                            variant="outlined"
                            value={this.state.sortKey}
                        >
                            {this.state.sortKeys.map((option, i) => (
                                <MenuItem key={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>             
                    </div>
                </div>
                <div className="models-container" id="modelList">
                    {this.renderModels()}
                </div>
                <div className="align-center" style={{marginTop: '20px'}}>
                    {this.state.loading && <CircularProgress />}
                </div>
                <div className="load-more-row">
                    <Button 
                        disabled={this.state.loadMore == false}
                        onClick={()=>this.fetchMore()}
                    >LOAD MORE</Button>      
                </div>
                <Dialog open={this.state.badgeInfoOpen} onClose={()=>this.setState({badgeInfoOpen: false})} aria-labelledby="Verfication Info">
                    <DialogContent>
                        <div>
                            <h3 style={{display: 'flex', alignItems: 'center'}}><VerifiedUserIcon style={{color: '#009be5'}}/> Verified Models</h3>
                            <p>Models with the blue verification badge meet the minimum criteria to become verified.</p>
                            <p>Verified models must have the following:</p>
                            <ul>
                                <li>100+ Images</li>
                                <li>200+ Tags</li>
                            </ul>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default (withStyles(styles)(ModelList));