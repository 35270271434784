import React from 'react';
import withAuth from './withAuth';
import Grid from '@material-ui/core/Grid';
import {Line} from 'react-chartjs-2';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Dropzone from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ImageIcon from '@material-ui/icons/Image';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import TransferIcon from '@material-ui/icons/CompareArrows';
import Button from '@material-ui/core/Button';
import PayCard from './PayCard';
import parse from 'html-react-parser';
import {send_notification} from '../Util/Helper';
import { EMPTY_TRAINING } from '../constants/TrainingFilters';
import moment from 'moment';
import StripesImg from '../images/stripes-light.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Badge from '@material-ui/core/Badge';
import FvLoading from '../images/FV_LOADING.gif';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


import {
    withSnackbar
  } from 'notistack';

const StyledRating = withStyles({
    iconFilled: {
      color: '#009be5',
    },
    iconHover: {
      color: '#0fb2ff',
    },
  })(Rating);


const CLOUD_DOMAIN = process.env.REACT_APP_CLOUD_DOMAIN;

class SingleModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Monthly Downloads',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgba(75,192,192,0.1)',
                        borderColor: '#009BE5',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: '#009BE5',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: []
                    }
                ]
            },
            model: null,
            canRate: false,
            numMonths: 5,
            canDownload: false,
            downloading: false,
            curBytes: 0,
            downloadSize: 0,
            transferring: false,
            runningPrediction: false,
        };
    }
    
    componentDidMount(){
        this.getModel();
        this.canRate();
        this.getDownloads();
        this.modelAccessCheck();
    }

    getModel(){
        const {modelId} = this.props.match.params;

        fetch('/api/models/entry/'+modelId,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            this.setState({model: data});
        }).catch(error=>{
            console.log(error);
        })
    }

    modelAccessCheck(){
        const {modelId} = this.props.match.params;

        fetch('/api/models/can_download/'+modelId,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            this.setState({canDownload: data});
        }).catch(error=>{
            console.log(error);
        })
    }

    getDownloads(){
        const {modelId} = this.props.match.params;
        const {numMonths} = this.state;

        fetch('/api/models/downloads/'+modelId+'/'+numMonths,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data){
                this.state.data.labels  = Object.keys(data).map(key =>{
                    return moment(parseInt(key)).format('MMM');
                });
                this.state.data.datasets[0].data = Object.values(data);
                this.setState({data: this.state.data});
            }
        }).catch(error=>{
            console.log(error);
        })  
    }

    canRate(){
        if(this.props.auth0.loggedIn()){
            const {modelId} = this.props.match.params;
            const user_id   = localStorage.getItem('user_id');
            fetch('/api/ratings/can_rate/'+modelId+'/'+user_id,{
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Content-type': 'application/json'
                }
            }).then(res=>{
                return res.json();
            }).then(data=>{
                this.setState({canRate: data});
            }).catch(error=>{
                console.log(error);
            })
        }else{
            return false;
        }
    }

    applyRating(rating){
        if (!rating) return;
        const {user_id, id, model_name} = this.state.model;
        const rater_id = localStorage.getItem('user_id');
        const ratingEntry = {
            'rating': rating,
            'owner_id': user_id,
            'rater_id': rater_id,
            'model_id': id
        }

        fetch('/api/ratings/',{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(ratingEntry)
        }).then(res=>{
            return res.json()
        }).then(data=>{
            this.state.model.rating       = data.average;
            this.state.model.rating_count = data.count;
            this.setState({model: this.state.model, canRate: false});
            send_notification('new_rating', user_id, ratingEntry.rating, this.state.model);
        }).catch(error=>{
            console.log(error);
        })
    }

    downloadModelEntry(){
        const {model} = this.state;
        const {user_id, id, model_name} = this.state.model;
        const access_token = localStorage.getItem('access_token');
        const version = model.deployed_version ? model.deployed_version : null;

        if (!version) return false;

        const data = {
            'model': model,
            'version': version,
        }
        fetch('/api/models/new_download',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(data)
        }).then(res=>{
            return res.json()
        }).then(data=>{
            send_notification('model_download', user_id, null, model);
        }).catch(error=>{
            console.log(error);
        })
    }

    async download(){
        if (this.state.downloading) return;
        const {model} = this.state;
        const version      = model.deployed_version ? model.deployed_version : null;
        const model_id     = model.id;
        const access_token = localStorage.getItem('access_token');

        if (!version){
            this.props.enqueueSnackbar('The model version is not available', {
                variant: 'error',
                action: (key) => (
                  <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                )
            });
            return false;
        }

        const path = CLOUD_DOMAIN+'/api/capture/models/peer_download/'+model_id+'/'+version;
        await fetch(path, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + access_token
            }
        }).then(async (response)=>{
            if (response.status >= 200 && response.status < 300){
                const reader = response.body.getReader();
                const contentLength = +response.headers.get('Content-Length'); 
                this.setState({downloading: true, downloadSize: contentLength});     
            
                let receivedLength = 0; 
                let chunks = []; 
                while(true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        break;
                    }
                
                    chunks.push(value);
                    receivedLength += value.length;
                    this.setState({curBytes: receivedLength});
                    console.log(`Received ${receivedLength} of ${contentLength}`)
                }
                
                let blob = new Blob(chunks);
            
                var a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.setAttribute("Download", `${model_id}#${version}.zip`);
                a.click();
                this.setState({downloading: false, curBytes: 0});
                this.downloadModelEntry();
            }else{
                console.log(response);
                this.props.enqueueSnackbar('You are unauthorized to download this model at this time', {
                    variant: 'error',
                    action: (key) => (
                      <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                    )
                });
            }
        }).catch((error)=>{
            console.log(error);
            this.props.enqueueSnackbar('You are unauthorized to download this model at this time', {
                variant: 'error',
                action: (key) => (
                  <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                )
            });
        });
    }

    transferModel(){
        this.setState({transferring: true});
        const {model} = this.state;
        const {user_id, id, model_name} = this.state.model;
        const version = model.deployed_version ? model.deployed_version : null;
        const access_token = localStorage.getItem('access_token');

        if (!version){
            this.props.enqueueSnackbar('Model version not found', {
                variant: 'error',
                action: (key) => (
                  <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                )
            });
            return false;
        }

        const path = CLOUD_DOMAIN+'/api/capture/project/project_transfer/'+model.id+'/'+version;
        fetch(path,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(EMPTY_TRAINING)
        }).then(res=>{
            return res.json()
        }).then(data=>{
            send_notification('model_transfer', user_id, null, model);
            this.setState({transferring: false});
            this.props.enqueueSnackbar('Model transferred successfully', {
                variant: 'success',
                action: (key) => (
                  <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                )
              });
        }).catch(error=>{
            console.log(error);
            this.setState({transferring: false});
            this.props.enqueueSnackbar('Model failed to transfer', {
                variant: 'error',
                action: (key) => (
                  <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                )
            });
        })       
    }

    canDownload=()=>{
        this.setState({canDownload: true});
    }

    loginCheck(canDownload){
        if (!this.props.auth0.loggedIn()){
            return(
                <Button onClick={()=>this.props.auth0.login()} variant="contained" color="primary">Login to download model</Button>
            )   
        }else{
            return(
                <div className="model-purchase">
                    {canDownload&&<ButtonGroup color="primary" variant="contained">
                        <Button size="large" onClick={()=>this.transferModel()} startIcon={<TransferIcon />}>Transfer</Button>
                        <Button size="large" onClick={()=>this.download()} startIcon={<GetAppIcon />}>Download</Button>                                
                    </ButtonGroup>}
                    {<PayCard model={this.state.model} price={this.state.model.price} showDownload={this.canDownload} showPayBtn={canDownload} />}
                </div>
            )
        }
    }

    handleDrop = files => {
        let fl = files[0]
        var reader = new FileReader();

        reader.addEventListener("load", function () {
          // convert image file to base64 string
          this.predictImg(reader.result);
        }.bind(this), false)
      
        if (fl) {
          reader.readAsDataURL(fl);
        } 
    }

    renderDropZone(){
        if (this.props.auth0.loggedIn()){
            return (
                <Dropzone className='dropzone' onDrop={this.handleDrop.bind(this)} multiple={false} style={{margin: 'unset'}}>
                    {!this.state.runningPrediction && <div style={{color: '#9c9c9c'}}>{'Click or Drop to run an inference'}</div>}
                    {this.state.runningPrediction && <div><img src={FvLoading} className="dropzone-fvloading" /></div>}
                </Dropzone>
            )
        }else{
            return (
                <div className="dropzone" style={{margin: 'unset'}}>
                    <Button onClick={()=>this.props.auth0.login()} variant="contained" color="primary">Please login to run inferences</Button>
                </div>
            )
        }
    }

    renderSvg = (base64, tags, size, index) => {
        if (tags !== undefined && base64 !== undefined) {
          let image = new Image();
          let width = size
          let height = size / 16 * 9
          let imgUrl = base64
          image.src = imgUrl;
    
          if (image.width && image.width > image.height) {
            width = size
            height = size / image.width * image.height
          } else if (image.width) {
            width = size / image.height * image.width
            height = size
          }
    
          let svg = tags.map(tag => {
            let box = tag['box']
            let [ymin, xmin, ymax, xmax] = [box[0], box[1], box[2], box[3]]
            let [left, right, top, bottom] = [(xmin * width), (xmax * width), (ymin * height), (ymax * height)]
            let [box_x, box_y] = [top, left]
            let [box_width, box_height] = [right - left, bottom - top]
            let hex = '#5f94c2'
    
            if (tag['flag'] !== undefined && tag['flag'].includes('#')) {
              hex = tag['flag']
            }
    
            return (
              <div key={Math.random()}>
                {size > 400 && (
                  <div>
                    <span style={{ color: 'white', fontWeight: 'bold', background: 'rgba(0, 0, 0, 0.5)', fontFamily: 'sans-serif', fontSize: '0.66em', position: 'absolute', top: box_x < 25 ? box_x : box_x - 20, left: box_y, padding: "2px 5px" }}>{tag['tag'].split('#')[0] + ' ' + (tag['score'] * 100).toFixed(0) + '%'}  </span>
                    {/* <span style={{ fontFamily: 'sans-serif', color: 'rgba(255, 255, 255, 1)', fontWeight: 'bold', fontSize: '.8em', position: 'absolute', top: box_x + box_height - 20, left: box_y + box_width - 30 }}>81%</span> */}
                  </div>
                )}
    
                <svg
                  width={box_width} height={box_height}
                  preserveAspectRatio="xMinYMin meet"
                  style={{ position: 'absolute', border: "3px solid " + hex, top: box_x, left: box_y, borderRadius: 4 }}>
                  <rect x="0" y="0" opacity="0.2" fill={hex} stroke="#000000" strokeMiterlimit="10" width={box_width} height={box_height} />
                </svg>
              </div>
            )
          })
    
          return (
            <div style={{ position: 'relative' }}>
              <img
                src={imgUrl}
                style={{
                  height: height,
                  width: width,
                  margin: '0 auto'
                }}
              />
              {svg}
            </div>
          )
        }
      } 

    predictImg(img) {
        this.setState({ imgb64: '', runningPrediction: true });
        const {id} = this.state.model;
        const path = CLOUD_DOMAIN+'/api/capture/predict/single_inference/'+encodeURI(id);
        fetch(path, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({ src: img })
        }).then(results => {
          this.setState({ runningPrediction: false });
          if (results.status === 404) {
            this.props.enqueueSnackbar('Project not found.', {
              variant: 'error',
              action: (key) => (
                <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
              )
            });
            this.setState({ status: 0 });
            return null;
          } else if (results.status === 500) {
            this.props.enqueueSnackbar('Picture could not be processed by server.', {
              variant: 'error',
              action: (key) => (
                <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
              )
            });
            this.setState({ status: 0 });
            return null;
          } else if (results.status === 502) {
            this.props.enqueueSnackbar('Server Request Timed out.', {
              variant: 'error',
              action: (key) => (
                <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
              )
            });
            this.setState({ status: 0 });
            return null;
          } else if (results.status === 401 || results.status === 403) {
            this.props.enqueueSnackbar('You have exceeded the daily inference limit - add tokens to continue', {
              variant: 'error',
              action: (key) => (
                <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
              )
            });
            this.setState({ status: 0 });
            return null;
          }
          else {
            return results.json();
          }
        }).then(data => {
          // console.log(data['tags']);
          data['base64'] = img

        //   this.handleData(data)
        //   this.props.enqueueSnackbar('Detection successful', {
        //     variant: 'success',
        //     anchorOrigin: {
        //       vertical: 'top',
        //       horizontal: 'center',
        //     },
        //   });
          this.setState({
            imgb64: data['base64'],
            status: 0,
            img: data,
            runningPrediction: false
          });
          setTimeout(() => {
            this.setState({
              status: 0,
            })
          }, 2000);
    
        }).catch(error => {
            console.log(error);;
          this.setState({ status: 0 });
          return null;
        });
    
    }

    getImage() {
        const {model}   = this.state;
        if (model && model.thumbnail_path != '') {
          return model.thumbnail_path
        }else{
          return StripesImg;
        }
    }

    renderSamples(){
        const {model}   = this.state;
        if (model && model.samples && model.samples.length>0) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <div style={{color: '#767676', width:'130px'}}>Click an image to run an inference: </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {model.samples.map((b64,i)=>{
                            return (
                                <Card key={i} className="sample-img" style={{width: '100px', height: '100px', margin: '10px'}} onClick={()=>this.predictImg(b64)}>
                                    <CardMedia
                                        style={{height: '200px'}}
                                        image={b64}
                                    />
                                </Card>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    render (){
        const {model}   = this.state;
        let canDownload = false;

        let draft = '';
        if (model){
            draft = model.description;
            canDownload = (model.price == 0) ? true : this.state.canDownload;
        }
 
        let uploadProgress  = 0;
        if (this.state.downloadSize){
          uploadProgress = ((this.state.curBytes/this.state.downloadSize)*100);
        }
    
        
        return (
            <div className="page-container">
                {model && <div>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={7} sm={12} xs={12}>
                            <div style={{display: 'flex'}}>
                                <div style={{paddingRight: '12px'}}>
                                    <div>
                                        <Typography variant="h3" >{model.model_name}</Typography>
                                        <p>{model.short_description}</p>
                                    </div>
                                    <div className="model-listing-txt">
                                        <div>Publisher: {model.publisher}</div>
                                        <div>Category: {model.category}</div>
                                    </div>
                                    <div className="model-data-highlights">
                                        <div><Badge badgeContent={model.images} max={999} children={<ImageIcon />} /></div>
                                        <div><Badge badgeContent={model.tags} max={999} children={<LocalOfferIcon/>} /></div>
                                        <div><Badge badgeContent={model.downloads} max={999} children={<GetAppIcon />} /></div>
                                    </div>
                                    <div className="model-keywords">
                                        {model.keyword_terms.map(kw =>{
                                            return (
                                                <div key={kw}>
                                                    <Chip label={kw} variant="outlined" />
                                                </div>                                    
                                            )
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <img src={this.getImage()} style={{maxHeight: '300px', maxWidth: '300px', borderRadius: '6px'}} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={5} sm={12} xs={12}>
                            <Line data={this.state.data} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-start" style={{marginTop: '20px'}}>
                        <Grid item md={4} sm={12} xs={12}>
                            <Paper className="model-img-viewer" style={{padding: '10px'}}>
                                {!this.state.img && <img src={StripesImg} style={{width: '100%'}} />}
                                {this.state.img && <div style={{height: '300px', overflow: 'auto'}}>
                                    {this.renderSvg(this.state.img['base64'], this.state.img['tags'], window.innerWidth < 600 ? window.innerWidth - 40 : window.innerWidth / 2.5)}
                                </div>}
                            </Paper>
                        </Grid>
                        <Grid item md={8} sm={12} xs={12}>
                            <Paper style={{padding: '10px'}}>
                                {this.renderSamples()}
                                <div >
                                    {this.renderDropZone()}
                                </div>
                                <Typography variant="h6" color="textSecondary" style={{marginTop: '10px'}}>Results</Typography>
                                <div className="model-prediction-results">                                
                                    {this.state.img && this.state.img.tags.map((tag,i)=>{
                                        const score = (tag.score*100);
                                        return (
                                            <Box key={i} display="flex" alignItems="center" style={{marginTop: '10px'}}>
                                                <Box minWidth={150} style={{textAlign: 'right', paddingRight: '5px'}}>
                                                    <Typography variant="body2" color="textSecondary">{tag.tag}</Typography>
                                                </Box>
                                                <Box width="100%" mr={1}>
                                                    <LinearProgress variant="determinate" value={score} />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                                        score,
                                                    )}%`}</Typography>
                                                </Box>
                                            </Box>
                                        )

                                    })}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-start" style={{marginTop: '20px'}}>
                        <Grid item xs={12}>
                            
                            <Paper style={{padding: '20px'}}>
                                <Typography variant="h4" color="textSecondary">Description</Typography>
                                {parse(draft)}
                            </Paper>
                        </Grid>
                    </Grid>
                    <div className="model-btm-block">
                        <div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginLeft: '30px'}}>
                                    <StyledRating
                                        readOnly={!this.state.canRate}
                                        name="simple-controlled"
                                        value={this.state.model.rating}
                                        precision={0.5}
                                        size="large"
                                        onChange={(event, newValue) => {
                                            this.applyRating(newValue);
                                        }}
                                    />
                                    <span style={{color: 'rgba(0,0,0,.4)'}}>({this.state.model.rating_count})</span>
                                </div>
                            </div>
                        </div>
                        {this.loginCheck(canDownload)}
                    </div>               
                </div>}
                <Dialog open={this.state.downloading}>
                    <DialogContent className="align-center">
                        <div>Downloading Model</div>
                        <div>
                        <span style={{fontSize: '.8em', color: '#009be5'}}>{this.state.curBytes/1000} </span>
                        <span style={{fontSize: '.8em'}}> of {this.state.downloadSize/1000} kb</span>
                        </div>
                        <div className="align-center">
                        <Box position="relative" display="inline-flex">
                            <CircularProgress variant="static" value={Math.round(uploadProgress)} />
                            <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            >
                            <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                uploadProgress,
                            )}%`}</Typography>
                            </Box>
                        </Box>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.transferring} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <div>Transferring model... </div>
                        <div><CircularProgress /></div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(withStyles()(SingleModel));