import React from 'react';
import StarRateIcon from '@material-ui/icons/StarRate';
import NotifyIcon from '@material-ui/icons/NotificationsNone';
import TransferIcon from '@material-ui/icons/CompareArrows';
import PurchaseIcon from '@material-ui/icons/AttachMoney';
import DownloadIcon from '@material-ui/icons/GetApp';

export const alertLabel=(alert)=>{
    const alertTable = {
        'new_rating': {'icon': <StarRateIcon/>, 'label': 'New Rating', 'color': '#F9A825'},
        'new_purchase': {'icon': <PurchaseIcon/>, 'label': 'New Purchase', 'color': '#4CAF50'},
        'model_download': {'icon': <DownloadIcon/>,'label': 'Model Download', 'color': '#7986CB'},
        'model_transfer': {'icon': <TransferIcon/>, 'label': 'Model Transfer', 'color': '#4DB6AC'},
        'default': {'icon': <NotifyIcon/>, 'label': 'New Alert', 'color': '#189be5'}
    }

    let type = alert.type;
    if (!(type in alertTable))
        type = 'default'

    return alertTable[type];
}
