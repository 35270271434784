import React from 'react';

import Paper from '@material-ui/core/Paper';
import {Line, Bar} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            tab: 0,
            interactionData: {
                labels: [],
                datasets: [
                    {
                        label: 'Interactions',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgba(75,192,192,0.1)',
                        borderColor: '#009BE5',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: '#009BE5',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: []
                    }
                ]
            },
            downloadsData: {
                labels: [],
                datasets: [
                    {
                        label: 'Downloads',
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: []
                    }
                ]
            },
            earningsData: {
                labels: [],
                datasets: [
                    {
                        label: 'Gross Earnings $',
                        backgroundColor: '#C8E6C9',
                        borderColor: '#388E3C',
                        borderWidth: 1,
                        hoverBackgroundColor: '#81C784',
                        hoverBorderColor: '#4CAF50',
                        data: []
                    }
                ]
            },
            models: [],
        };
    }

    componentDidMount(){
        this.getRatings();
        this.getDownloads();
        this.getEarnings();
        this.props.props.history.push('?tab='+this.state.tab);
    }

    getRatings(){
        fetch('/api/analytics/my_ratings',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data&&'data_count' in data){
                this.state.interactionData.labels           = Object.keys(data.data_count).map(key =>{
                    return moment(parseInt(key)).format('MMM');
                });
                this.state.interactionData.datasets[0].data = Object.values(data.data_count);
                this.setState({interactionData: this.state.interactionData});
            }

        }).catch(error=>{
            console.log(error);
        })
    }

    getDownloads(){
        fetch('/api/analytics/my_downloads',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data && 'data_count' in data){
                this.state.downloadsData.labels = Object.keys(data.data_count).map(key =>{
                    return moment(parseInt(key)).format('MMM');
                });
                this.state.downloadsData.datasets[0].data = Object.values(data.data_count);
                this.setState({downloadsData: this.state.downloadsData});
            }

        }).catch(error=>{
            console.log(error);
        })
    }

    getEarnings(){
        fetch('/api/analytics/my_earnings',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data&&'contexts' in data){
                const data_count = data.data_count;
                const contexts   = data.contexts;

                this.state.earningsData.labels           = Object.keys(data_count).map(key =>{
                    return moment(parseInt(key)).format('MMM');
                });
                this.state.earningsData.datasets[0].data = Object.values(contexts).map(dataset=>{
                    let sum = 0;
                    if (dataset.length>0){
                        sum = dataset.reduce((sum, x) => Number.parseInt(sum) + Number.parseInt(x));
                    }
                    return sum
                });

                this.setState({earningsData: this.state.earningsData});
            }

        }).catch(error=>{
            console.log(error);
        })
    }

    renderDashboard(){
        return (
            <div>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={6} sm={12} xs={12}>
                        <Paper>
                            <Bar
                                data={this.state.downloadsData}
                                height={250}
                                options={{
                                    maintainAspectRatio: false
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Paper>
                            <Bar
                                data={this.state.earningsData}
                                height={250}
                                options={{
                                    maintainAspectRatio: false
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12}>
                        <Paper>
                            <Line data={this.state.interactionData} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }


    render(){
        return (
            <div>{this.renderDashboard()}</div>
        )
    }
}

export default Dashboard;