import {SET_MENU_OPEN, MEDIA_OPEN, MEDIA_CLOSE, MENU_INIT, UPDATE_ACTIVE, TAG_MENU_OPEN, CURSOR_MENU_OPEN, TOGGLE_BINDS_MENU } from '../actions/menu';

// reducer
export default function menuReducer(state = MENU_INIT, props) {
    let newState = cloneObj(state);
    switch (props.type) {

        case SET_MENU_OPEN:
            return {...state, isOpen: !state.isOpen};
        case MEDIA_OPEN:
          // if (props.myTraining)
          //   newState.myTraining = props.myTraining; // when null we assume trainReducer has the data
          // newState.activePhoto = props.activePhoto;
          // newState.showingMediaBrower = true;
          // return newState;
          return {...state, activePhoto: props.activePhoto, myTraining: props.myTraining, showingMediaBrower: true,handleImageUpload:props.handleImageUpload }
        case MEDIA_CLOSE:
          newState.showingMediaBrower = false;
          return newState;
        case UPDATE_ACTIVE:
            return {...state, activePath: props.path}
        case TAG_MENU_OPEN:
          return {...state, tagMenuOpen: !state.tagMenuOpen }
        case CURSOR_MENU_OPEN:
          return {...state, cursorMenuOpen: !state.cursorMenuOpen}
        case TOGGLE_BINDS_MENU:
          return {...state, toggleBindsMenu: !state.toggleBindsMenu}
        default:
            return state;
    }
}

function cloneObj(obj) {
  return JSON.parse(JSON.stringify(obj)); // clone without reference
}
