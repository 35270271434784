import React from 'react';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';


import Alerts from './account_tabs/Alerts';
import Sales from './account_tabs/Sales';
import Purchases from './account_tabs/Purchases';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

class Account extends React.Component {
    constructor() {
        super();
        this.state = {
            tab:2,
            Vtab: 0,
            moreAlerts: false,
            morePurchaseHistory: false,
            moreSalesHistory: false,
            userSettings: null,
            pageSize: 20,
            loadMoreAlerts: false,
            lastAlert: null,
            alerts: [],
            loginLink: null
        }
    }

    componentDidMount(){
        this.getAlertSettings(); 
        this.paramTab();
    }

    handleVtabChange = (event, newValue) => {
        this.setState({Vtab: newValue});
        this.props.props.history.push(`?tab=${this.state.tab}&vtab=${newValue}`);
    };

    paramTab(){
        const urlParams = new URLSearchParams(window.location.search);
        const Vtab = urlParams.get('vtab');

        if (Vtab != null){
            this.handleVtabChange(null, parseInt(Vtab));
        }
    }

    getAlertSettings(){
        fetch('/api/alerts/settings',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data){
                if (!data.alerts_seen) this.setAlertsSeen(data);
                if (data.stripe_account){
                    this.getAccountLink();
                }
                this.setState({userSettings: data});
            }else{
                //init alert settings
                this.initAlertSettings();
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    initAlertSettings(){
        fetch('/api/alerts/settings',{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({})    
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data) this.setState({userSettings: data});
        }).catch(error=>{
            console.log(error);
        })
    }

    updateAlertSettings(e){
        let {userSettings}          = this.state;
        if (e.target.name!='publisher_name'){
            userSettings[e.target.name] = e.target.checked;
        }
        

        fetch('/api/alerts/settings',{
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(userSettings)    
        }).then(res=>{
            return res.json();
        }).then(data=>{
            this.setState({userSettings});
        }).catch(error=>{
            console.log(error);
        }) 
    }

    setAlertsSeen(alert){
        alert.alerts_seen = true;
        fetch('/api/alerts/settings',{
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(alert)    
        }).then(res=>{
            return res.json();
        }).then(data=>{
            this.setState({userSettings: data});
        }).catch(error=>{
            console.log(error);
        })     
    }

    updatePublisher(e){
        this.state.userSettings.publisher_name = e.target.value;
        this.setState({userSettings: this.state.userSettings});
    }

    renderAlertSettings(){
        const {userSettings} = this.state;
        if(userSettings){
            return (
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <h4>Manage Alerts</h4>
                        <div className="alert-row">
                            <div>Ratings:</div>
                            <div>
                                <Switch
                                    color="primary"
                                    checked={userSettings.new_rating}
                                    onChange={(e)=>this.updateAlertSettings(e)}
                                    name="new_rating"
                                />
                            </div>
                        </div>                        
                        <div className="alert-row">
                            <div>Purchases:</div>
                            <div>
                                <Switch
                                    color="primary"
                                    checked={userSettings.new_purchase}
                                    onChange={(e)=>this.updateAlertSettings(e)}
                                    name="new_purchase"
                                    
                                />
                            </div>
                        </div>                             
                        <div className="alert-row">
                            <div>Model Downloads:</div>
                            <div>
                                <Switch
                                    color="primary"
                                    checked={userSettings.model_download}
                                    onChange={(e)=>this.updateAlertSettings(e)}
                                    name="model_download"
                                />
                            </div>
                        </div>                        
                        <div className="alert-row">
                            <div>Model Transfers:</div>
                            <div>
                                <Switch
                                    color="primary"
                                    checked={userSettings.model_transfer}
                                    onChange={(e)=>this.updateAlertSettings(e)}
                                    name="model_transfer"
                                />
                            </div>
                        </div> 
                    </Grid>
                </Grid>
            )
        }
    }

    renderStripeConnect(){
        const {userSettings} = this.state;
        const stripe_connect_url = process.env.REACT_APP_STRIPE_CONNECT_URL;
        if(userSettings && !userSettings.stripe_account){
            return (
                <Button 
                    variant="contained"
                    color="primary"
                    href={stripe_connect_url} target="_blank">
                    Monetize Models
                </Button>
            )
        }
    }

    async getAccountLink(){
        if (!this.state.loginLink){
            await fetch('/api/user/view_account',{
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                    'Content-type': 'application/json'
                }
            }).then(res=>{
                return res.json();
            }).then(data=>{
                if ('url' in data){
                    this.setState({'loginLink': data.url})
                }
            }).catch(error=>{
                console.log(error);
            }) 
        }

    }

    renderAccountLoginLink(){
        if (this.state.loginLink){
            return (
                <Button target="_blank" href={this.state.loginLink} variant="contained" color="primary">Account Dashboard</Button>
            )
        }
    }
    


    renderAccount(){
        return (
            <div>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Paper className="vtabs-item">
                            <Tabs
                                orientation="vertical"
                                value={this.state.Vtab}
                                onChange={this.handleVtabChange}
                            >
                                <Tab label="Notifications"  />
                                <Tab label="Purchase History"  />
                                <Tab label="Sales History"  />
                                <Tab label="Settings"  />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9}>
                        <Paper>
                            <TabPanel value={this.state.Vtab} index={0}>
                                <Alerts />
                            </TabPanel>
                            <TabPanel value={this.state.Vtab} index={1}>
                                <Purchases />
                            </TabPanel>
                            <TabPanel value={this.state.Vtab} index={2}>
                                <Sales />
                            </TabPanel>
                            <TabPanel value={this.state.Vtab} index={3}>
                                <Grid container spacing={2} alignItems="flex-start" justify="space-between">
                                    <Grid item>
                                        {this.renderStripeConnect()}
                                        {this.renderAccountLoginLink()}
                                    </Grid>
                                    <Grid item>
                                        <div style={{display: 'flex'}}>
                                            <TextField
                                                id="outlined-textarea"
                                                label="Publisher Name"
                                                multiline
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e)=> this.updatePublisher(e)}
                                                name="publisher_name"
                                                value={this.state.userSettings ? this.state.userSettings.publisher_name : ''}
                                            />
                                            <Button onClick={(e)=>this.updateAlertSettings(e)}>Save</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>
                                    {this.renderAlertSettings()}
                                </div>
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render(){
        return (
            <div>{this.renderAccount()}</div>
        )
    }
}

export default Account;