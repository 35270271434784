import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers';

import { EMPTY_TRAINING } from '../constants/TrainingFilters';

const store = createStore(
  rootReducer,
//  {myTraining:EMPTY_TRAINING},
  compose(applyMiddleware(thunk, logger), window.devToolsExtension ? window.devToolsExtension() : f => f)
);
export default store;
