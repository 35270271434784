import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import AuthService from './AuthService';
import withAuth from './withAuth';
const Auth = new AuthService();

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class ModelDelete extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            id:'',
            formDisabled: true,
            match: false,
            loading: false,
            loading_text: 'Deleting '

        };
    }
    
    handleChange = event => {
        if (event.name == 'name' && event.target.value !== '')
            return;

        let name = event.target.value;
        let match = false;
        if(name === this.props.model.model_name.toString()) {
            match = true;
            console.log('match')
        }
        this.setState({ 'name': name, 'match': match })
    };

    componentDidMount() {
        this.setState({
            name: null,
            id: null,
            formDisabled: true,
            match: false,
            versions: []

        });
    }
    componentWillUnmount() {
        this.setState({
            name: null,
            id: null,
            formDisabled: true,
            match: false,
            versions: []
        });
    }

    isDisabled = () => {
        console.log(this.state.match);
        return this.state.match !== true
    }

    handleDelete(){
        this.setState({
            name: null,
            id: null,
            formDisabled: true,
            match: false,
            versions: []
        });
        this.props.delete();
    }

    renderContent(){
        if (this.props.model){
            return (
                <div>

                    <Dialog open={this.state.loading} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            {this.state.loading_text}
                            <CircularProgress />
                        </DialogContent>
                    </Dialog>
                
                    <Dialog open={this.props.open} onClose={this.props.close} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <div className="ModelDelete" id={'delete-'+this.props.model.model_name}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <h4 style={{color: '#9f9f9f'}}>
                                            Delete {this.props.model.model_name}?
                                        </h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Enter the model name <strong>{this.props.model.model_name}</strong> below to confirm deletion.
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            name="name"
                                            variant="outlined"
                                            fullWidth
                                            onChange={event => this.handleChange(event)}
                                            value={this.state.name}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.close} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={()=>this.handleDelete()} color="secondary" disabled={this.isDisabled()}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
    }

    render() {

        return (
            <Paper>
                {this.renderContent()}    
            </Paper>
            
        );
    }
}

export default withSnackbar(withStyles(styles)(ModelDelete));
