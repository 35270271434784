import React from 'react';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

class Purchases extends React.Component {
    constructor() {
        super();
        this.state = {
            alertSettings: null,
            pageSize: 20,
            loadMore: false,
            fetching: false,
            purchases: []
        }
    }

    componentDidMount(){
        this.getSales();
    }


    getSales(){
        fetch('/api/purchases/history/-1/'+this.state.pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            if (data.length == this.state.pageSize)
                this.state.loadMore = true;
                

            if (data.length>0){
                this.setState({purchases: data, loadMore: this.state.loadMore});
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    loadMore(){
        const {alerts} = this.state;
        if (alerts){
            const alertsList = Object.values(alerts);
            const nextRowId = alertsList[alertsList.length-1].id
            this.fetchMore(nextRowId, this.state.pageSize);
        }
    }

    fetchMore(nextRowId, pageSize){
        this.setState({fetching: true});
        fetch('/api/user/sales/'+nextRowId+'/'+pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data.length == this.state.pageSize){
                this.state.loadMore = true;
            }else{
                this.setState({loadMore: false, fetching: false});
            }
                
                
            if (data.length>0){
                data.map(alert=>{this.state.alerts[alert.id]=alert})
                this.setState({alerts: this.state.alerts, loadMore: this.state.loadMore, fetching: false});
            }

        }).catch(error=>{
            console.log(error);
            this.setState({fetching: false});
        })     
    }

    render(){
        return (
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Model</TableCell>
                                <TableCell align="right">Purchased At</TableCell>
                                <TableCell align="right">Pruchase Price</TableCell>
                                <TableCell align="right">Version</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.purchases.map((row) => (
                                <TableRow 
                                    key={row.id}                                 
                                >
                                    <TableCell component="th" scope="row">
                                        {row.model_name}
                                    </TableCell>
                                    <TableCell align="right">{moment(row.created).format('MMM Do, YY HH:mm')}</TableCell>
                                    <TableCell align="right">{row.purchase_price}</TableCell>
                                    <TableCell align="right">{row.version}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="load-more-row">
                    {!this.state.fetching&&<Button 
                            disabled={this.state.loadMore == false}
                            onClick={()=>this.loadMore()}
                        >LOAD MORE</Button> }
                        {this.state.fetching && <CircularProgress size={24} />}     
                    </div>
                </TableContainer>
            </div>
        )
    }
}

export default Purchases;