import ReactGA from 'react-ga';

export default function gaEvent(category, action, value){
    ReactGA.event({
        category: category + ` (${window.location.hostname})`,
        action: action,
        label: value,
    });
}

export function initializeReactGA(user_id = null) {
    const uid = user_id ? user_id : localStorage.getItem('user_id');
    if (uid){
        ReactGA.initialize('UA-154431051-3', {
            gaOptions: {
                appId: window.location.hostname,
                userId: uid
            }
        })
    }

}