import decode from 'jwt-decode';
export default class AuthService {
    // Initializing important variables
    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch('/api/capture/auth/login', {
            method: 'POST',
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        }).then(res => {
            this.setToken(res.access_token) // Setting the token in localStorage
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(this.getIDToken()) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken, refreshToken) {
        // Saves user token to localStorage
        localStorage.setItem('access_token', idToken)
        if (refreshToken)
            localStorage.setItem('refresh_token', refreshToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('access_token');
    }

    getIDToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token');
    }

    logout() {
        // Clear user token and profile data from localStorage
        if (localStorage.getItem('access_token')) {
            this.fetch('/api/capture/auth/logout', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + this.getToken()
                }
            }).then(() => {
                localStorage.removeItem('access_token');

            })
            this.fetch('/api/process/auth/logout', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + this.getToken()
                }
            }).then(() => {
                localStorage.removeItem('access_token');

            })
        }
            if (localStorage.getItem('refresh_token')) {
                this.fetch('/api/capture/auth/logout2', {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
                    }
                }).then(() => {
                    localStorage.removeItem('refresh_token');

                }).catch(() => {
                    localStorage.removeItem('refresh_token');
                })
                this.fetch('/api/process/auth/logout2', {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
                    }
                }).then(() => {
                    localStorage.removeItem('refresh_token');

                }).catch(() => {
                    localStorage.removeItem('refresh_token');
                })
            }


    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getIDToken());
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            try{
                var error = new Error(response.statusText)
                error.response = response
                throw error
            }catch(err) {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                console.log(err)
            }
        }
    }
}
