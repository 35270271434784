import * as types from '../constants/ActionTypes';
import _ from 'lodash';
import { EMPTY_TRAINING } from '../constants/TrainingFilters';

const countChildren = function(tags, myT) {
  for(var i=0; i < tags.length; i++) {
    var t = tags[i];
    // if (typeof myT.ux[t.flag] != 'undefined') {
    //   myT.ux[t.flag].count++;
    // } else {
    //   alert('unknown category: ' + t.flag);
    // }

    if (myT.tags[t.title]) {
      myT.tags[t.title].count++;
    }

    if (t.children && t.children.length > 0) {
      myT = countChildren(t.children, myT);
    }
  }
  return myT;
}

const updateTrainingCounts = function(myT) {
  for(let n in myT.tags) {
    myT.tags[n].count = 0;
  }
  for(let n in myT.cameras) {
    myT.cameras[n].count = 0;
  }
  for(let n in myT.ux) {
    myT.ux[n].count = 0;
  }
  for(let n=0; n < myT.photos.length; n++) {
    if (myT.photos[n].camera && typeof myT.cameras[myT.photos[n].camera] != 'undefined') {
      myT.cameras[myT.photos[n].camera].count++;
    }
    myT = countChildren(myT.photos[n].children, myT);
  }
  return myT;
}

const validateType2Tool = function(photos, tool, title) {
  for (var i=0; i < photos.length; i++) {
    var p = photos[i];
    if (p.children && p.children.length > 0) {
      for (var i2=0; i2 < p.children.length; i2++) {
        var t = p.children[i2];
        if (t.tagInstance) {
          console.log(title + ' = ' + t.tool + ' vs. ' + tool);
          if (!t.tool) t.tool = 'tagBox'; // WARN: this is update ALLLL tags with this default
          if (t.title === title && t.tool !== tool) {
            console.log('FAILED');
            return t.tool; // return pre-existing tool definiton
          }
        } else if (t.children && t.children.length > 0) {
          console.log('validate type:tool on nested photos and tags')
          return validateType2Tool(t.children, tool, title);
        }
      }
    }
  }
  return true;
}

export default function trainReducer(state = EMPTY_TRAINING, action) {

  let newState = cloneObj(state), photo = null, tag = null;

  switch (action.type) {

    case types.START_TRAINING:
      newState          = cloneObj(action.myTraining);
      // const activePhoto = newState.activePhoto;
      newState.activePhoto = newState.photos[0]
      if (newState.id == null) newState.id = randId(); // null ID is used in the TrainingForm to check if new or editing; doubt it'll ever reach here
      return newState;

    case types.ACTIVE_TRAINING:
      return newState;

    case types.DELETE_TRAINING:
      return newState;

    case types.EDIT_TRAINING:
      for(var i in action.props) {
        if (typeof newState[i] != 'undefined') {
          newState.unsavedEdits = true;
          newState[i] = action.props[i];
          if (i == 'activePhoto') {
            newState.photos.forEach(function(p, index) {
              if (p.photoId  === newState.activePhoto.photoId) {
                newState.photos[index] = newState.activePhoto;
              }
            })
          }
        } else {
          console.log('skip property not part of myTraining: ', i);
        }
      }

      if (!newState.zoomWidth) newState.zoomWidth = newState.activePhoto.dimensions.width;
      if (!newState.zoomHeight) newState.zoomHeight = newState.activePhoto.dimensions.height;
      
      return newState;

    case types.UPDATE_COLORS:
        newState.ux[action.payload.catId] = action.payload.data;
        newState.unsavedEdits = true;
        return newState;

    case types.UPDATE_TRAINING: // TODO: depreciate in save of editTraining
        if (action.myTraining) newState = action.myTraining;
        newState.unsavedEdits = true;
        return newState;

    case types.DELETE_PHOTO:
      newState.unsavedEdits = true;

      if (action.photo.photoId == newState.activePhoto.photoId) {
        delete newState.activePhoto;
      }

      // newState.photos.forEach(function(p, index) {
      //   if (p.photoId == action.photo.photoId) {
      //     delete newState.photos.splice(index, 1);
      //     newState.activePhoto = (newState.photos.length > index - 1) ? newState.photos[0] : newState.photos[index];
      //
      //     return false;
      //   }
      // });

      let filterPhotos = newState.photos.filter(photo => {
        if (photo.photoId != action.photo.photoId) return photo;
      })

      newState.photos      = filterPhotos;
      newState.activePhoto = filterPhotos[0];
      newState = updateTrainingCounts(newState);
      // return {...state, photos: filterPhotos}
      return newState;

    case types.CLONE_PHOTO:
      action.photo.id = 'test';
      action.photo._id = 'testing';
  
      action.photo.photoId = randId();
      let rand = randId()
      action.photo.id = rand
      action.photo._id = rand
      action.photo.children.map(tag => {tag.tagInstance = randId(); tag.photoId = action.photo.photoId})
      action.photo.title += ' - CLONED';
      newState.photos.push(action.photo);
      newState.activePhoto = action.photo;
      newState = updateTrainingCounts(newState);
      newState.unsavedEdits = true;
      return newState;

    case types.ADD_CATEGORY:
      newState.ux[action.cat.name] = action.cat;
      newState = updateTrainingCounts(newState);
      newState.unsavedEdits = true;
      return newState;

    case types.ADD_FEATURE:
      action.feature.name = action.feature.name.replace('_', '.');
      newState.tags[action.feature.name] = action.feature;
      newState = updateTrainingCounts(newState);
      newState.unsavedEdits = true;
      return newState;

    case types.UPDATE_TAG:
      tag = action.activeTag;
      if (!tag.tool) tag.tool = 'tagBox'; // default
      // validate tool is same as all of tag types;
      var test = validateType2Tool(state.photos, tag.tool, tag.title);
      console.log("validateType2Tool == " + test, tag);
      if (test !== true) {
        alert(tag.title + ' must be a ' + test.substring('tag'.length));
        tag.tool = test;
        newState.activeTag = tag; // revert change
        return newState;
      }
      if (tag.title) newState.tags[tag.title].tool = tag.tool;

      tag.modified = new Date().getTime();
      newState.activeTag = tag;
      newState.activePhoto.children.forEach(function(t, index) {
        if (t.tagInstance == tag.tagInstance) {
          newState.activePhoto.children[index] = tag;
        }
      });
      newState.photos.forEach(function(p, index) {
        if (p.photoId == tag.photoId) {
          newState.photos[index].children.forEach(function(t, i) {
              if (t.tagInstance == tag.tagInstance) {
                newState.photos[index].children[i] = tag; // WARN: this doesn't search further nested children
              }
          });
        }
      })
      newState = updateTrainingCounts(newState);  // TODO don't run if it's just a shape change
      newState.unsavedEdits = true;
      return newState;
      
    case types.UPDATE_COLOR:
      tag = action.tag;
      newState.activePhoto.children.forEach(function(t, index) {
        if (t.title == tag.title) {
          newState.activePhoto.children[index].color = tag.color;
          newState.activePhoto.children[index].flag = tag.flag;
        }
      });
      newState.photos.forEach(function(p, index) {
        newState.photos[index].children.forEach(function(t, i) {
            if (t.title == tag.title) {
              newState.photos[index].children[i].color = tag.color; // WARN: this doesn't search further nested children
              newState.photos[index].children[i].flag = tag.flag
            }
        });
      })
      newState = updateTrainingCounts(newState);  // TODO don't run if it's just a shape change
      newState.unsavedEdits = true;
      return newState;

    case types.INSERT_TAG: // TODO VALIDATION??
        tag = action.activeTag;
        tag.modified = new Date().getTime();
        newState.activeTag = tag;
        newState.activePhoto.children.push(tag);
        newState.photos.forEach(function(p, index) {
          if (p.photoId == tag.photoId) {
            newState.photos[index].children.push(tag);
          }
        })
        newState = updateTrainingCounts(newState);  // TODO don't run if it's just a shape change
        newState.unsavedEdits = true;
        return newState;

      case types.DELETE_TAG:
        tag = action.activeTag;
        if (newState.activeTag && newState.activeTag.tagInstance == tag.tagInstance) {
          delete newState.activeTag;
        }
        if (newState.activePhoto) {
          newState.activePhoto.children.forEach(function(t, index) {
            if (t.tagInstance == tag.tagInstance) {
              newState.activePhoto.children.splice(index, 1);
            }
          });
        }
        newState.photos.forEach(function(p, index) {
          if (p.photoId == tag.photoId) {
            newState.photos[index].children.forEach(function(t, i) {
                if (t.tagInstance == tag.tagInstance) {
                  newState.photos[index].children.splice(i, 1);
                }
            });
          }
        })
        newState = updateTrainingCounts(newState);
        newState.unsavedEdits = true;
        return newState;

    case types.ACTIVE_FEATURE_CHANGE:
      tag = action.payload;

      photo = newState.photos.filter(p =>
        p.photoId == tag.photoId
      )
      if (photo.length > 0) {
        photo = photo[0];
      } else {
        // TODO: throw invalid error
        return newState;
      }

      newState.activeTag = tag;
      newState.activePhoto = photo;
      return newState;
      //
      // console.log(state.activePhoto.children);
      // return {...state, activePhoto: photo, activeTag: tag}

    case types.ACTIVE_PHOTO_CHANGE:
      // photo = action.payload;
      // newState.activePhoto = photo;
      delete newState.activeTag;
      // return newState;
      // const {width, height} = action.payload.dimensions;

      delete newState.zoomHeight;
      delete newState.zoomWidth;


      return {...newState, activePhoto: action.payload}

    case types.SYNC_TRAINING:
      return {...state, response: action.payload}

    case types.UPDATE_ZOOM:
      const scale = (action.payload.scale) ? action.payload.scale : state.scale;
      return {...state, zoomWidth: action.payload.width, zoomHeight: action.payload.height, scale}

    case types.EMPTY_DATA:
      return EMPTY_TRAINING;

    case types.REARRANGE_TAG:
      const removeTag = _.remove(state.activePhoto.children, function(tag){
        return tag.tagInstance == action.payload.tag.tagInstance;
      });
      // find the associated photo with the tag being updated
      const tagPhoto = newState.photos.filter(p =>
        p.photoId == action.payload.tag.photoId
      );
      const rearrangedChildren = (action.payload.placement === 'back') ? [removeTag[0], ...state.activePhoto.children] : [...state.activePhoto.children, removeTag[0]];
      const sortTags           = _.sortBy(rearrangedChildren, [function(tag) { if (tag.locked) return tag; }]);

      tagPhoto[0].children          = sortTags;
      newState.activePhoto.children = sortTags;
      return newState

      // return {
      //   ...state,
      //   activePhoto: {
      //     ...state.activePhoto,
      //     children: rearrangedChildren
      //   },
      // }
    case types.DUPLICATE_TAG:
      const newTagIdx      = state.activePhoto.children.length;
      const newTagInstance = new Date().getTime();
      const newTag         = _.cloneDeep(action.payload.tag);
      if (newTag) {
        newTag.tagInstance   = newTagInstance;
        newTag.index         = newTagIdx;
        newTag.modified      = newTagInstance;
        const tagIdx         = state.activePhoto.children.indexOf(action.payload.tag);

        //add cloned tag object to children and return a new array
        const tags = state.activePhoto.children.splice(tagIdx+1, 0, newTag);
      }

      const addTagToChildren = [...state.activePhoto.children]
      return {
        ...state,
        activePhoto: {
          ...state.activePhoto,
          children: addTagToChildren
        },
      }

    case types.MOVE_TAG:
      const rearrangedTags = {};
      const binds          = newState.tags;
      const tagName        = action.payload.tagName;
      const index          = action.payload.index;

      //turn moved tag into an array + remove the tag from tags object
      const moveObj = [tagName, binds[tagName]]
      delete binds[tagName]

      //turn the tags object into an array
      const tagList = Object.entries(binds).map(([key, value]) => {
        return [key, value]
      });

      //add move object at index in new tags array
      tagList.splice(index, 0, moveObj);
      tagList.map(tag => rearrangedTags[tag[0]] = tag[1]);
      newState.tags = rearrangedTags;

      return newState;

    case types.COPY_TAGS:
      const copyPhotos = state.photos.filter(photo => {
        return (photo.photoId == action.payload.img_id) ? photo.copy_tags = true : photo;
      });
      if (copyPhotos) newState.photos = copyPhotos;
      return newState;
    case types.PASTE_TAGS:
      let copyImgIdx  = null;
      let pasteImgIdx = null;
      const allImgs = state.photos.map((photo, i) => {
        if (photo.copy_tags) copyImgIdx      = i;
        //if (photo.copy_tags) photo.copy_tags = false;
        if (action.payload.img_id == photo.photoId) pasteImgIdx = i
        return photo;
      });

      if (allImgs && copyImgIdx != null && pasteImgIdx != null) {
        const deepCloneTags           = _.cloneDeep(allImgs[copyImgIdx].children);

        const mergedTags = allImgs[pasteImgIdx].children

        copyTags(deepCloneTags).map(tag => {
          if (!tag.locked) {
            tag.photoId = state.activePhoto.photoId
            mergedTags.push(tag)
          }
        })
        // const mergedTags              = _.concat(copyTags(deepCloneTags), allImgs[pasteImgIdx].children)
        allImgs[pasteImgIdx].children = mergedTags;
        newState.photos               = allImgs;
        newState.activePhoto.children = mergedTags;
      }

      return newState;
    case types.CANCEL_COPY:
      const copyImgs = state.photos.filter(photo => {
        if (photo.copy_tags) photo.copy_tags = false;
        return photo;
      });
      if (copyImgs) newState.photos = copyImgs;
      return newState;

    default:
      return state
  }

}

function cloneObj(obj) {
  return JSON.parse(JSON.stringify(obj)); // clone without reference
}

function randId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function copyTags(photoTags) {
  const newTags = photoTags.map(tag => {
    var min = 9999999;
    var max = 99999999999999;
    tag.tagInstance = Math.round(Math.random() * (min, max) + min);
    tag.modified    = new Date().getTime();
    return tag
  });
  return newTags;
}
