export const industries = [
    'Advertising',
    'Aerospace',
    'Agriculture',
    'Art',
    'Automation',
    'Communications',
    'Construction',
    'Education',
    'Entertainment',
    'Farming',
    'Fashion',
    'Finance',
    'Environmental',
    'Hospitality',
    'Information',
    'Infrastructure',
    'IT',
    'Manufacturing',
    'Materials',
    'Media',
    'Music',
    'Publishing',
    'Retail',
    'Robotics',
    'Service',
    'Space',
    'Technology',
    'Telecom'
]