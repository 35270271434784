import React, { Component } from 'react';
import AuthService from './AuthService';
import ReactGA from 'react-ga';

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();
     return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null
            }
        }

        componentWillMount() {
            const path = this.props.history.location.pathname;
            const host = window.location.hostname;
            ReactGA.pageview(
                path,
                [],
                `Flexible Vision (${host})`
            );
            if (!this.auth0Authenticate()) {
                console.log('not logged in');
                this.props.history.replace('/login')
            }
            else {
                try {
                    const profile = Auth.getProfile()
                    this.setState({
                        user: profile
                    })
                }
                catch(err){
                    console.log(err)
                    Auth.logout()
                    this.props.history.replace('/login')
                }
            }
        }

        auth0Authenticate() {
          return Auth.loggedIn();
        }

        render() {
            if (this.state.user) {
                return (
                    <AuthComponent history={this.props.history} user={this.state.user} />
                )
            }
            else {
                return null
            }
        }
     }
 }
