export const ERROR = 'ERROR'
export const COMPLETE_ALL = 'COMPLETE_ALL'
export const CLEAR_COMPLETED = 'CLEAR_COMPLETED'
export const SET_SYNC_STATE = 'SET_SYNC_STATE'
export const ACTIVE_TRAINING = 'ACTIVE_TRAINING'
export const ACTIVE_PHOTO_CHANGE = 'ACTIVE_PHOTO_CHANGE'
export const ACTIVE_FEATURE_CHANGE = 'ACTIVE_FEATURE_CHANGE'

// tags & features
export const ADD_FEATURE = 'ADD_FEATURE'
export const INSERT_TAG = 'INSERT_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const UPDATE_TAG = 'UPDATE_TAG'
export const UPDATE_COLOR = 'UPDATE_COLOR'

// trainings
export const LOAD_TRAINING   = 'LOAD_TRAINING'
export const START_TRAINING  = 'START_TRAINING'
export const DELETE_TRAINING = 'DELETE_TRAINING'
export const EDIT_TRAINING   = 'EDIT_TRAINING'
export const UPDATE_TRAINING = 'UPDATE_TRAINING'
export const SYNC_TRAINING   = 'SYNC_TRAINING'
export const UPDATE_ZOOM     = 'UPDATE_ZOOM';
export const EMPTY_DATA      = 'EMPTY_DATA';
export const REARRANGE_TAG   = 'REARRANGE_TAG';
export const DUPLICATE_TAG   = 'DUPLICATE_TAG';
export const MOVE_TAG        = 'MOVE_TAG';
export const COPY_TAGS       = 'COPY_TAGS';
export const CANCEL_COPY     = 'CANCEL_COPY';
export const PASTE_TAGS      = 'PASTE_TAGS';

// images
export const ADD_PHOTO = 'ADD_PHOTO'
export const INSERT_PHOTO = 'INSERT_PHOTO'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const CLONE_PHOTO = 'CLONE_PHOTO'
export const EDIT_PHOTO = 'EDIT_PHOTO'
export const UPDATE_PHOTO = 'UPDATE_PHOTO'
export const COMPLETE_PHOTO = 'COMPLETE_PHOTO'

export const UPDATE_COLORS = 'UPDATE_COLORS';
export const ADD_CATEGORY = 'ADD_CATEGORY';
