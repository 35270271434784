import React from 'react';
import _ from 'lodash';
import moment from 'moment';


import {alertLabel} from '../../../helpers/accountHelper';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import '../profile.scss';

class Alerts extends React.Component {
    constructor() {
        super();
        this.state = {
            alertSettings: null,
            pageSize: 20,
            loadMore: false,
            lastAlert: null,
            alerts: [],
            fetching: false
        }
    }

    componentDidMount(){
        this.getAlerts();
    }

    getAlerts(){
        fetch('/api/alerts/-1/'+this.state.pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            let alertsObj = {};
            if (data.length == this.state.pageSize)
                this.state.loadMore = true;

            data.map(alert=>alertsObj[alert.id]=alert);
            this.setState({
                alerts: alertsObj,
                lastAlert: data[data.length-1].id,
                loadMore: this.state.loadMore
            });
        }).catch(error=>{
            console.log(error);
        })
    }

    loadMore(){
        const {alerts} = this.state;
        if (alerts){
            const alertsList = Object.values(alerts);
            const nextRowId = alertsList[alertsList.length-1].id
            this.fetchMore(nextRowId, this.state.pageSize);
        }
    }

    fetchMore(nextRowId, pageSize){
        this.setState({fetching: true});
        fetch('/api/alerts/'+nextRowId+'/'+pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data.length == this.state.pageSize){
                this.state.loadMore = true;
            }else{
                this.setState({loadMore: false, fetching: false});
            }
                
                
            if (data.length>0){
                data.map(alert=>{this.state.alerts[alert.id]=alert})
                this.setState({alerts: this.state.alerts, loadMore: this.state.loadMore, fetching: false});
            }

        }).catch(error=>{
            console.log(error);
        })     
    }

    deleteAlert(alert){
        fetch('/api/alerts/delete/'+alert.id,{
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            delete this.state.alerts[alert.id];
            this.setState({alerts: this.state.alerts});
        }).catch(error=>{
            console.log(error);
        })
    }

    clearAll(){
        fetch('/api/alerts/clear_all',{
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            this.setState({alerts: {}, loadMore: false});
        }).catch(error=>{
            console.log(error);
        })
    }

    render(){
        return (
            <div>
                <div className="alert-table-hdr">
                    <div style={{color: '#d5d5d5', paddingRight: '5px'}}>clear all</div>
                    <IconButton aria-label="delete" onClick={()=>this.clearAll()} >
                        <ClearAllIcon />
                    </IconButton>
                </div>
                {Object.values(this.state.alerts).map(alert=>{
                    const labelRef = alertLabel(alert);
                    return (
                        <div key={alert.id} className="single-list-row">
                            <div className="single-row-icon">
                                <Chip
                                    icon={labelRef.icon}
                                    label={labelRef.label}
                                    color="primary"
                                    variant="outlined"
                                    style={{color: labelRef.color, border: `1px solid ${labelRef.color}`}}
                                />
                            </div>
                            <div>{alert.message}</div>
                            <div style={{marginLeft: 'auto'}}>
                                <span className="alert-timestamp">{moment(alert.created).format('MMM Do, YY HH:mm')}</span>
                                <IconButton aria-label="delete" onClick={()=>this.deleteAlert(alert)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    )
                })}
                <div className="load-more-row">
                   {!this.state.fetching&&<Button 
                        disabled={this.state.loadMore == false}
                        onClick={()=>this.loadMore()}
                    >LOAD MORE</Button> }
                    {this.state.fetching && <CircularProgress size={24} />}     
                </div>
            </div>
        )
    }
}

export default Alerts;