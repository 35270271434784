import React from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";

class ConnectCallback extends React.Component {

    componentDidMount(){
        //get code from url params 
        //post with params to finalize connect
        //store user connect id in database
        this.verifyAccount();
    }

    verifyAccount(){
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        // fetch("https://connect.stripe.com/oauth/token", {
        //     method: "post",
        //     headers: {
        //         Accept: "application/json",
        //         'Access-Control-Allow-Origin': "https://connect.stripe.com",
        //     },
        //     body: JSON.stringify({
        //       code: code,
        //       grant_type: "authorization_code"
        //     })
        //   })
        //   .then(response => console.log(response));


          fetch("/api/user/connect_account", {
            method: "post",
            headers: {
                Accept: "application/json",
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
            },
            body: JSON.stringify({
              connect_id: code,
              grant_type: "authorization_code"
            })
          })
          .then(response => console.log(response));
    }

    render(){
        const user_id = localStorage.getItem('user_id');
        return (
            <div className="page-container">
                <Paper className="small-card-center">
                    <h2>Account Connected!</h2>
                    <h4 style={{color: '#565656'}}>You are ready to accept payments</h4>
                    <p>Add a purchase price to your models in the model editor to start charging.</p>
                    <MonetizationOnIcon className="monitized"/>
                    <div style={{margin: '10px'}}>
                        <Button variant="contained" onClick={()=>window.location='/user/'+user_id}>View Profile</Button>
                    </div>
                    
                </Paper>
            </div>
        )
    }
}   

export default ConnectCallback;