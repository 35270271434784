import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {send_notification} from '../Util/Helper';

import CardSection from './CardSection';

export default function CheckoutForm({payIntent, billing, document, next, showDownload}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const billingEmpty = billing.phone=="" || billing.name=="" || billing.email=="";

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(payIntent, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billing,
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      setErrorMessage(result.error.message);
      setLoading(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        console.log('payment succeeded');
        const purchaser_id = localStorage.getItem('user_id');
        const access_token = localStorage.getItem('access_token');
        const data = {
          'model': document,
          'purchaser_id': purchaser_id,
          'version': 123
        }
        fetch('/api/purchases/',{
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token
          },
          body: JSON.stringify(data)
        }).then(res=>{
            return res.json()
        }).then(data=>{
          console.log(data);
        }).catch(error=>{
            console.log(error);
        })
        
        next();
        showDownload();
        setLoading(false);
        send_notification('new_purchase', document.user_id, null, document);
      }
    }
  };

  const renderSubmit = () => {
    if (stripe&&!billingEmpty){
      if(!loading){
        return (
          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" >Process Payment</button>
        )
      }else{
        return (
          <CircularProgress />
        )
      }
    }
  }


  return (
    <div>
      <p style={{color: '#E64A19'}}>{errorMessage}</p>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <div style={{textAlign: 'center', marginTop: '20px'}}>
          {renderSubmit()}
        </div>
      </form>
    </div>
  );
}
