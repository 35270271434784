import React, { Component } from 'react';
import AuthService from './AuthService';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        }
    }
});

class Login extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
        this.state = {
            'username': '',
            'password': ''
        }
    }

    componentWillMount() {
        this.checkCookieCredentials();
        if (this.Auth.loggedIn())
            this.props.history.replace('/');
    }

    componentDidMount() {
        this.timer = setInterval(() => this.isUserLoggedIn(), 1000);
        if (!this.Auth.loggedIn()){
            this.props.auth0.login();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    login = () => {
        this.props.auth0.login();
    }

    isUserLoggedIn() {
        if (this.Auth.loggedIn()) this.props.history.replace('/projects/list')
    }

    checkCookieCredentials() {
        const access_token = Cookies.get('access_token');
        const id_token     = Cookies.get('id_token');
        if (access_token && id_token) {
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('isLoggedIn', true);
        }
    }

    render() {
        return (
            <div className="center">
                <Grid container alignItems="center">
                    {/* <Grid item xs={12}>
                        <Typography variant="h2">Login</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="username"
                            name="username"
                            label="User Name"
                            onChange={event => this.handleChange(event)}
                            value={this.state.username}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            name="password"
                            type='password'
                            label="Password"
                            onChange={event => this.handleChange(event)}
                            value={this.state.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Button onClick={this.handleFormSubmit} color="primary">
                        Login
                    </Button>
                    </Grid> */}
                    <Paper className={'fv-inner-container login-card'}>
                        <Fab variant="extended" color="primary" aria-label="add" onClick={this.login} >
                            <NavigationIcon style={{marginRight: '5px'}}/>
                            Login
                        </Fab>
                    </Paper>
                </Grid>

            </div>
        );
    }

    handleChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit = e => {
        e.preventDefault();
        console.log('logging in')
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.replace('/');
            })
            .catch(err => {
                this.props.enqueueSnackbar('Login Attempt Failed', { 
                    variant: 'error',
                    action: (key) => (
                        <div onClick={() => { this.props.closeSnackbar(key) }} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} />     
                    )}
                );
            })
    }
}

export default withSnackbar(withStyles(styles)(Login));