// constants
export const CAM_GET_CAM = 'CAM_GET_CAM';
export const CAM_GET_PHOTO = 'CAM_GET_PHOTO';
export const CAM_GET_STREAM = 'CAM_GET_STREAM';
export const CAM_GET_LIST = 'CAM_GET_LIST';
export const CAM_CLEAR_STREAM = 'CAM_CLEAR_STREAM';
export const GET_CAPTURES = 'GET_CAPTURES';

export const CAM_INIT = {allCameras: [], activeCamera : false, stream : null, camBlob : null, captures: {}};

// actions
export function returnPhoto(camBlob) {
  return { type : CAM_GET_PHOTO, camBlob : camBlob }
}
export function returnCameras(allCameras) {
  return { type : CAM_GET_LIST,  allCameras : allCameras }
}
export function returnStream(stream) {
  return { type : CAM_GET_STREAM,  stream : stream }
}
export function stopStream() {
  return {type: CAM_CLEAR_STREAM}
}

export function takePhoto(stream) {
  return function (dispatch) {
    const track = stream.getVideoTracks()[0];
    var imageCapture = new window.ImageCapture(track);
    imageCapture.takePhoto() // better resolution than ImageCapture.grabFrame()
      .then(blob => {
        var reader = new FileReader();
         reader.readAsDataURL(blob);
         reader.onloadend = function() {
             dispatch(returnPhoto(reader.result));
         }
      })
    .catch(error => console.error('takePhoto() error:', error));
  }
}

export function getCameraCapabilities(stream) {
  // imageCapture.getPhotoCapabilities();
}

export function getStream(camId) {
  return function (dispatch) {
    if (document.location.protocal === 'http:') {
      return alert('You must you HTTPS for camera access');
    }
    navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
    const constraints = {video: {deviceId: {exact: camId}}};

    return navigator.getMedia(constraints, function(stream) {
      dispatch(returnStream(stream));
    }, function(err) {
      console.log(err);
    });
  }
}

export function scanCameras() {
  return function(dispatch) {

    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      let allCameras = [];
      for (let i = 0; i < devices.length; i++) {
        const info = devices[i];
        if (info.kind === 'videoinput') {
          console.log('video input: ', info);
          allCameras.push(info);
        } else {
          // console.log('ignore device: ', info.deviceId);
        }
      }
      dispatch(returnCameras(allCameras));

    }).catch((err) => console.log(err));
  };
}

export function getCaptures(captures) {
  return {type: GET_CAPTURES, payload: {captures}}
}
