export const EMPTY_TRAINING = function() {
  return {
    id: null,
    name: '',
    created : new Date().getTime(),
    modified : new Date().getTime(),
    ux: {
      cat1: {name:'Default', color:'#5f94c2', count:0, alpha:50}
    },
    cameras: {},
    tags: {},
    trainUrl : process.env.TRAIN_SERVER, // create training models
    detectUrl : process.env.DETECT_SERVER, // test images
//    minioUrl : process.env.MINIO_URL, // download base
    nodeUrl: process.env.NODE_SERVER || document.location.origin, // webpack-hotloader runs on different server than node
//    webUrl: process.env.WEB_URL || document.location.origin, // webpack-hotloader runs on different server than node
    // rootDir : process.env.API_DIR || '', // this was meant as temporary user / 'account' level groupings. obsolete in favor of hardcoded 'projects' folder
    notificationEmail: 'aaron@flexibleassembly.com',
    sensitivity: 0,
    predict: false,
    mlserver: false,
    model: '',
    version: '0',
    tfserver: '',
    tfport: 8501,
    photos: []
  //  activePhoto : {},
  //  activeTag : {}
  }
}();

export const toolSet = {
  'tagBox' : {name: 'Box', icon : require('../images/icons/Tag_Box.svg')},
  'tagBarcode' :{name: 'Barcode', icon : require('../images/icons/Tag_Barcode.svg')},
  'tagOCR' : {name: 'Text / OCR', icon : require('../images/icons/Tag_OCR.svg')},
};
