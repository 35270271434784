import React from 'react';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

class Sales extends React.Component {
    constructor() {
        super();
        this.state = {
            alertSettings: null,
            pageSize: 20,
            loadMore: false,
            lastAlert: null,
            fetching: false,
            sales: []
        }
    }

    componentDidMount(){
        this.getSales();
    }


    getSales(){
        const token = localStorage.getItem('access_token');
        fetch('/api/user/sales/-1/'+this.state.pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(async data=>{
            if (data.length == this.state.pageSize)
                this.state.loadMore = true;
                

            if (data.length>0){
                this.setState({sales: data, loadMore: this.state.loadMore});
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    loadMore(){
        const {sales} = this.state;
        if (sales){
            const salesList = Object.values(sales);
            const nextRowId = salesList[salesList.length-1].id
            this.fetchMore(nextRowId, this.state.pageSize);
        }
    }

    fetchMore(nextRowId, pageSize){
        this.setState({fetching: true});
        const token = localStorage.getItem('access_token');
        fetch('/api/user/sales/'+nextRowId+'/'+pageSize,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data.length == this.state.pageSize){
                this.state.loadMore = true;
            }else{
                this.setState({loadMore: false, fetching: false});
            }
                
            if (data.length>0){
                const sales_list = _.concat(this.state.sales, data);
                this.setState({sales: sales_list, loadMore: this.state.loadMore, fetching: false});
            }
        }).catch(error=>{
            console.log(error);
            this.setState({fetching: false});
        })     
    }

    render(){
        return (
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Model</TableCell>
                                <TableCell align="right">Sold At</TableCell>
                                <TableCell align="right">Sell Price</TableCell>
                                <TableCell align="right">Version</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.sales.map((row) => (
                                <TableRow 
                                    key={row.model_id}                                 
                                >
                                    <TableCell component="th" scope="row">
                                        {row.model_name}
                                    </TableCell>
                                    <TableCell align="right">{moment(row.created).format('MMM Do, YY HH:mm')}</TableCell>
                                    <TableCell align="right">{row.purchase_price}</TableCell>
                                    <TableCell align="right">{row.version}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="load-more-row">
                        {!this.state.fetching&&<Button 
                            disabled={this.state.loadMore == false}
                            onClick={()=>this.loadMore()}
                        >LOAD MORE</Button> }
                        {this.state.fetching && <CircularProgress size={24} />}    
                    </div>
                </TableContainer>
            </div>
        )
    }
}

export default Sales;