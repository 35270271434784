import { combineReducers } from 'redux';

import menuReducer from './menuReducer';
import cameraReducer from './cameraReducer';
import trainReducer from './trainReducer';
import programReducer from './programReducer';

export default (combineReducers({
    'menu': menuReducer,
    'cameraReducer': cameraReducer,
    'trainReducer': trainReducer,
    'programReducer': programReducer
}));
