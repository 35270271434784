import React from 'react';

import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './profile.scss';
import _ from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';

import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';

import { EditorState, convertToRaw } from 'draft-js';
import TextField from '@material-ui/core/TextField';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {stateFromHTML} from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import ModelDelete from '../ModelDelete';
import * as I from '../../constants/Industries';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';


import {
    withSnackbar
  } from 'notistack';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Models extends React.Component {
    constructor() {
        super();
        this.state = {
            tab: 1,
            models: [],
            page: 0,
            rowsPerPage: 20,
            editRow: null,
            anchorEls: {},
            editOpen: false,
            editorState: null,
            nextRowId: -1,
            loadMore: false,
            categories: I.industries,
            errors: [],
            fullModels: {},
            updating: false,
            stripeAccount: false

        }
    }

    componentDidMount(){
        this.getModels();
        this.getStripeAccount();
        this.props.props.history.push('?tab='+this.state.tab);
    }

    getStripeAccount(){
        fetch('/api/user/view_account',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if ('url' in data){
                this.setState({stripeAccount: true});
            }
        }).catch(error=>{
            console.log(error);
        }) 
    }

    getModels(){        
        fetch('/api/models/entries/'+this.state.nextRowId+'/'+this.state.rowsPerPage,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data.length == this.state.rowsPerPage)
                this.state.loadMore = true;

            if (data.length>0){
                let fullModels = {}
                data.map(model=> fullModels[model.id] = model);
                this.setState({models: data, fullModels, loadMore: this.state.loadMore});
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    loadMore(){
        const {models} = this.state;
        if (models){
            const nextRowId = models[models.length-1].id
            this.fetchMore(nextRowId, this.state.rowsPerPage);
        }
    }

    fetchMore(nextRowId, rowsPerPage){
        fetch('/api/models/entries/'+nextRowId+'/'+rowsPerPage,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('access_token'),
                'Content-type': 'application/json'
            }
        }).then(res=>{
            return res.json();
        }).then(data=>{
            if (data.length == this.state.rowsPerPage){
                this.state.loadMore = true;
            }else{
                this.setState({loadMore: false, fetching: false});
            }
                
            if (data.length>0){
                const model_list = _.concat(this.state.models, data);
                this.setState({models: model_list, loadMore: this.state.loadMore});
            }

        }).catch(error=>{
            console.log(error);
        })     
    }

    handleMenuOpen = (event, row) => {
        let anchorEls = Object.assign({}, this.state.anchorEls);
        anchorEls[row.id] = event.currentTarget;
        this.setState({ 'anchorEls': anchorEls, 'selected': row.id, 'editRow': row });
        this.initEditorState(row);
    }

    handleMenuClose = (row) => {
        let anchorEls = Object.assign({}, this.state.anchorEls);
        anchorEls[row.index] = null;
        this.setState({ 'anchorEls': anchorEls, 'selected': '', 'editRow': null });
    }

    initEditorState(editRow){
        if(editRow){
            var contentState = stateFromHTML(editRow.description);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState: editorState});
        }
    }

    onEditorStateChange = (newState) => {
        this.setState({editorState: newState, editRow: this.state.editRow})
    }

    updateModelField(key, value){
        if(this.state.editRow){
            if (key == 'keyword_terms'){
                const terms             = value.split(' ');
                this.state.editRow[key] = terms;
            }else{
                this.state.editRow[key] = value;
            }
            this.setState({editRow: this.state.editRow, errors: {} });
        }
    }

    async saveUpdates(){
        this.setState({updating: true});
        const model       = this.state.editRow;
        const isValidated = await this.validated(model);

        if (isValidated){
            const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
            const draft = draftToHtml(rawContentState);
            model.description = draft;

            if (model){
                const token = localStorage.getItem('access_token');
                await fetch('/api/models/entry/'+model.id,{
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer '+token,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(model)
                }).then(res=>{
                    this.setState({editRow: null, editOpen: false, errors: {}, updating: false });
                    if(res.status < 300){
                        this.props.enqueueSnackbar('Model updated', {
                            variant: 'success',
                            action: (key) => (
                              <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                            )
                        });
                    }else{
                        this.props.enqueueSnackbar('Update failed', {
                            variant: 'error',
                            action: (key) => (
                              <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                            )
                        });
                    }
                }).catch(error=>{
                    console.log(error);
                    this.setState({updating: false});
                    this.props.enqueueSnackbar('Update failed', {
                        variant: 'error',
                        action: (key) => (
                          <div onClick={() => { this.props.closeSnackbar(key) }} className="snackbar-close" />
                        )
                    });
                })
            }
        }else{
            this.setState({updating: false});
        }
    }

    validated(model){
        const fieldsToValidate = ['language', 'short_description', 'keyword_terms', 'category']
        fieldsToValidate.map(field =>{
            if (field == 'short_description'){
                if (model[field].length > 150){
                    this.state.errors[field] = 'Short description has to be 150 characters or less'
                }
            }                
            if (model[field].length == 0){
                this.state.errors[field] = 'Can\'t be blank'
            }
        })
        this.setState({errors: this.state.errors});
        return Object.keys(this.state.errors).length == 0
    }

    deleteEntry= async ()=>{
        const model = this.state.editRow;
        const token = localStorage.getItem('access_token');
        if (model){
            await fetch('/api/models/entry/'+model.id,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token,
                }
            }).then(res=>{
                delete this.state.fullModels[model.id]
                this.setState({
                    editRow: null, 
                    editOpen: false, 
                    fullModels: this.state.fullModels, 
                    deleteOpen: false
                });
            }).catch(error=>{
                console.log(error);
            })
        }  
    }

    handleDrop = async files => {
        let fs = []
        let uploads = await files.map(file => {
            var reader = new FileReader();
            var result = reader.addEventListener("load", function () {
                let model     = this.state.editRow;
                if (!this.state.editRow.samples)
                    this.state.editRow.samples = [];
                const samples = [reader.result, ...this.state.editRow.samples];
                const images  = _.take(samples, 4);
                model.samples = images;
                this.setState({editRow: model});
            }.bind(this), false)
            
            if (file) {
                reader.readAsDataURL(file);
            } 
        })
    }

    handleDeleteClose=()=>{
        this.setState({deleteOpen: false, editOpen: false, editRow: null});
    }

    setCoverImg=(file)=>{
        let model = this.state.editRow;
        model.thumbnail_path = file;
        this.setState({editRow: model});
    }

    removeImg(index){
        const {samples} = this.state.editRow;   
        if (samples) {
            samples.splice(index, 1);
            this.setState({editRow: this.state.editRow});
        }
    }

    updateLoading = () => {
        let innerText = '';
        if (this.state.updating){
            innerText = (
                <CircularProgress />
            )
        }else{
            innerText = (
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={()=>this.saveUpdates()}
                >Update</Button>
            )
        }
        return (
            <div style={{marginTop: '20px', textAlign: 'center'}}>
                {innerText}
            </div>
        )
    }

    renderModels(){
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Model</TableCell>
                                <TableCell align="right">Created At</TableCell>
                                <TableCell align="right">Category</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(this.state.fullModels).map((row) => (
                                <TableRow 
                                    key={row.model_name}                                 
                                    hover
                                    
                                >
                                    <TableCell component="th" scope="row">
                                        {row.model_name}
                                    </TableCell>
                                    <TableCell align="right">{moment(row.created).format('MMM Do, YY HH:mm')}</TableCell>
                                    <TableCell align="right">{row.category}</TableCell>
                                    <TableCell align="center">{row.price}</TableCell>
                                    <TableCell align="center"><div className={'status status-'+row.status}>{row.status}</div></TableCell>
                                    <TableCell align="right" style={{width: '40px'}}>
                                        <IconButton
                                            aria-label="Options"
                                            aria-owns={Boolean(this.state.anchorEls[row.id]) ? 'menu-' + row.id : undefined}
                                            aria-haspopup="true"
                                            onClick={event => this.handleMenuOpen(event, row)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="load-more-row">
                        <Button 
                            disabled={this.state.loadMore == false}
                            onClick={()=>this.loadMore()}
                        >LOAD MORE</Button>      
                    </div>
                </TableContainer>

                {this.state.editRow && <Menu anchorEl={this.state.anchorEls[this.state.editRow.id]} open={Boolean(this.state.anchorEls[this.state.editRow.id])} onClose={event => this.handleMenuClose(this.state.editRow)}>
                    <MenuItem onClick={() => window.location='/model/'+this.state.editRow.id}><ViewIcon className="modal-menu-icon" /> View</MenuItem>
                    <MenuItem onClick={() => this.setState({editOpen: true})}><EditIcon className="modal-menu-icon" /> Edit</MenuItem>
                    <MenuItem onClick={() => this.setState({deleteOpen: true})}><DeleteIcon className="modal-menu-icon" /> Delete</MenuItem>
                </Menu>}
                <Dialog 
                    onClose={()=>this.setState({editOpen: false, anchorEls: {}, errors: {} })} 
                    open={this.state.editOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    {this.state.editRow && <div style={{padding: '20px'}}>
                        <Grid container spacing={2} alignItems="center" justify="space-between">
                            <Grid item xs={12} sm={7}>                                
                                <h2 style={{display: 'flex', alignItems: 'center'}}>
                                    <div>{this.state.editRow && <Avatar alt="Cindy Baker" src={this.state.editRow.thumbnail_path} style={{width: '60px', height: '60px'}} />}</div>
                                    <div style={{marginLeft: '10px'}}>{this.state.editRow.model_name}</div>
                                </h2>
                                <div className="edit-first-row-fmt">
                                    <div>
                                        <TextField
                                            select
                                            label="Status"
                                            className="filter-select"
                                            onChange={(event) => this.updateModelField('status',event.target.value)}
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.editRow.status}
                                        >
                                            {['published', 'draft'].map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <div className="basic-lbl">Allow Resharing</div>
                                        <Switch
                                            checked={this.state.editRow.allow_resharing}
                                            onChange={(event)=>this.updateModelField('allow_resharing',event.target.checked)}
                                            color="primary"
                                            name="allow_resharing"
                                        />
                                    </div>
                                    <div style={{width: '200px'}}>
                                        <FormControl className="form-control-chips">
                                            <InputLabel id="mutiple-chip-label">Types</InputLabel>
                                            <Select
                                                multiple
                                                value={this.state.editRow?this.state.editRow.types:[]}
                                                onChange={(event)=>this.updateModelField('types',event.target.value)}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <div className="chips" >
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} className="chip" />
                                                        ))}
                                                    </div>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {['TF', 'TF Lite'].map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{padding: '10px'}}>
                                    <TextField
                                        error={'language' in this.state.errors}
                                        helperText={'language' in this.state.errors ? this.state.errors['language'] : ''}
                                        select
                                        fullWidth
                                        label="Language"
                                        className="filter-select"
                                        onChange={(event) => this.updateModelField('language',event.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.editRow.language}
                                    >
                                        {['English'].map((option, i) => (
                                            <MenuItem key={i} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <div className="model-edit-cat-field">
                                        <TextField
                                            error={'category' in this.state.errors}
                                            helperText={'category' in this.state.errors ? this.state.errors['category'] : ''}
                                            select
                                            fullWidth
                                            label="Category"
                                            className="filter-select"
                                            onChange={(event) => this.updateModelField('category',event.target.value)}
                                            variant="outlined"
                                            value={this.state.editRow.category}
                                        >
                                            {this.state.categories.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>                           
                                    </div>
                                    <div>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-amount"
                                                value={this.state.editRow.price}
                                                disabled={!this.state.editRow.deployed_version || !this.state.stripeAccount}
                                                onChange={(event)=>this.updateModelField('price',event.target.value)}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={60}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{padding: '10px'}}>
                                    <TextField
                                        fullWidth
                                        error={'keyword_terms' in this.state.errors}
                                        helperText={'keyword_terms' in this.state.errors ? this.state.errors['keyword_terms'] : ''}
                                        label="Key Words"
                                        helperText="Add key words (space separated)"
                                        className="filter-select"
                                        onChange={(event) => this.updateModelField('keyword_terms',event.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.langFilter}
                                    />
                                    <div className="model-keywords">
                                        {this.state.editRow.keyword_terms.map(kw =>{
                                            return (
                                                <div key={kw}>
                                                    <Chip label={kw} variant="outlined" />
                                                </div>                                    
                                            )
                                        })}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>{(this.state.editRow && this.state.editRow.samples) && <div className="cover-img-txt">Tap an image to set cover photo.</div>}</div>
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>                                    
                                    {(this.state.editRow && this.state.editRow.samples) && this.state.editRow.samples.map((file,i)=>{
                                        return (      
                                            <div key={i} style={{position: 'relative'}}>                                      
                                                <Card className="sample-img" style={{width: '100px', height: '100px', margin: '10px'}} onClick={(e)=> this.setCoverImg(file)}>
                                                    <CardMedia
                                                        style={{height: '200px'}}
                                                        image={file}
                                                    />
                                    
                                                </Card>
                                                <IconButton 
                                                    color="secondary" 
                                                    variant="contained" 
                                                    style={{position: 'absolute', bottom: '0px', right: '-5px', background: 'white'}}
                                                    onClick={()=>this.removeImg(i)}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{padding: '10px'}}>                                    
                                    <Dropzone className='dropzone' onDrop={this.handleDrop.bind(this)} multiple={true} style={{width: '100%', height: '34px',margin: 'unset', padding: '0px'}}>
                                        <div className="dropzone-inner-txt" >{'Add sample photos'}</div>
                                    </Dropzone>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={12}>
                                <TextField
                                    error={'short_description' in this.state.errors}
                                    helperText={'short_description' in this.state.errors ? this.state.errors['short_description'] : 'Characters: '+(150-this.state.editRow.short_description.length)}
                                    id="outlined-textarea"
                                    label="Short Description"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e)=>this.updateModelField('short_description',e.target.value)}
                                    value={this.state.editRow.short_description}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={12}>
                                <Paper>
                                    <Editor
                                        editorState={this.state.editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        {this.updateLoading()}
                    </div>}
                </Dialog>
                <ModelDelete 
                    open={this.state.deleteOpen} 
                    model={this.state.editRow} 
                    close={this.handleDeleteClose} 
                    delete={this.deleteEntry}
                />
            </div>
        )
    }

    render(){
        return (
            <div>{this.renderModels()}</div>
        )
    }
}

export default withSnackbar(Models);