import React from 'react';
import history from './history';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

class Callback extends React.Component {
  componentDidMount() {
    setTimeout(
      function() {
        this.props.history.push('/');
      }
      .bind(this),
      1500
    );
  }

  render() {
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
          <DialogContent>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>Logging In.. </div>
              <div><CircularProgress /></div>
            </div>
          </DialogContent>
      </Dialog>
    );
  }
}


export default Callback;
