import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountIcon from '@material-ui/icons/AccountBalanceWallet';

import Account from './Account';
import Models from './Models';
import Dashboard from './Dashboard';
import withAuth from "../withAuth";


class Profile extends React.Component {
    constructor() {
        super();
        this.state = {
            tab: 0,
            tabs: {},
        };
    }
    
    componentDidMount(){
        this.paramTab();
        this.initTabs();
    }

    initTabs(){
        this.setState({
            tabs: {
                0: {icon: <DashboardIcon/>, label: 'DASHBOARD', component: <Dashboard props={this.props}/>}, 
                1: {icon: <BlurLinearIcon />,label: 'MODELS', component: <Models props={this.props} />}, 
                2: {icon: <AccountIcon />, label: 'ACCOUNT', component: <Account props={this.props}/>}
            },
        })
    }

    tabContent(){
        const {tab, tabs} = this.state;
        if (Object.keys(tabs).length>0){
            const curTab      = tabs[tab];

            return curTab.component;
        }

    }

    paramTab(){
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab');

        if (tab != null && tab < 3){
            this.setState({tab: parseInt(tab)});
        }
    }

    render (){
        const handleChange = (event, newValue) => {
            this.setState({tab: newValue})
        };

        return (
            <div className="page-container">
                <Tabs
                    value={this.state.tab}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                >
                    {Object.keys(this.state.tabs).map(key=>{
                        const tab = this.state.tabs[key];
                        return (
                            <Tab key={key} icon={tab.icon} label={tab.label} id={'tab'+key} />
                        )
                        
                    })}
                </Tabs>
                <div className="page-container" style={{paddingBottom: '0px'}}>
                    {this.tabContent()}
                </div>
            </div>
        )
    }
}

export default withAuth(Profile);
;