import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import {initializeReactGA} from './Util/ga_event';
import { Button } from 'reactstrap'; // required for cascading

import { BrowserRouter } from 'react-router-dom';
import Logger from './Util/logger';

import store from './stores/store';

import App from './App';
import { SnackbarProvider } from 'notistack';

import * as serviceWorker from './serviceWorker';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

Logger.Clear();

const STRIPE_PK     = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PK);


initializeReactGA();
ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
        <SnackbarProvider anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </SnackbarProvider>
        </BrowserRouter>
      </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
