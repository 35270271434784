import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import NumberFormat from 'react-number-format';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckoutForm from './CheckoutForm';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class PayCard extends React.Component {
    constructor() {
        super();
        this.state = {
            showPaywall: false,
            billingDetails: {
                email: '',
                phone: '',
                name: ''
            },
            payIntent: null,
            steps: ['Contact Details', 'Process Payment', 'Payment Complete'],
            activeStep: 0,
            canDownload: false
        };
    }

    setBillingDetails=(e)=>{
        const {billingDetails}        = this.state;
        billingDetails[e.target.name] = e.target.value;
        this.setState({billingDetails});
    }

    initializePayment(){
        const {model}      = this.props;
        const access_token = localStorage.getItem('access_token');
        const data = {
            'model': model,
            'version': 123
        }
        fetch('/api/models/init_payment',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(data)
        }).then(res=>{
            return res.json()
        }).then(data=>{
            if(data){
                console.log(data)
                this.setState({showPaywall: true, payIntent: data})
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    handleChange = async (event) => {
        const {billingDetails} = this.state;
        const details = event.target.value;
        this.state.billingDetails.phone = details;
        await this.setState({billingDetails: this.state.billingDetails});
        const element = document.getElementById('phone');
        element.focus(); 
    };

    NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;
      
        return (
          <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
            format="+1 (###) ###-####" 
            mask="_"
          />
        );
    }

    getStepContent(step) {
        switch (step) {
          case 0:
            return (
                <div style={{padding: '20px'}}>
                    <div style={{marginBottom: '10px', padding: '5px 15px 5px 15px'}}>
                        <TextField
                            id="columns"
                            name="name"
                            type="text"
                            label="Full-name"
                            onChange={(e)=>this.setBillingDetails(e)}
                            fullWidth={true}
                            value={this.state.billingDetails.name}
                            InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            id="columns"
                            name="email"
                            type="email"
                            label="Email"
                            onChange={(e)=>this.setBillingDetails(e)}
                            style={{marginTop: '10px', marginBottom: '10px'}}
                            fullWidth={true}
                            value={this.state.billingDetails.email}
                            InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Phone Number"
                            name="phone"
                            fullWidth
                            type="phone"
                            value={this.state.billingDetails.phone}
                            onChange={this.handleChange}
                            id="phone"
                            InputProps={{
                                startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                            }}
                        />

                    </div>                        
                </div>
            )
          case 1:
            return (
                <div style={{padding: '20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{color: '#b9b9b9'}}>Confirm Purchase</div>
                        <div style={{fontSize: '2em',color: '#189be5'}}>${this.props.price}</div>
                    </div>
                    <CheckoutForm 
                        payIntent={this.state.payIntent} 
                        billing={this.state.billingDetails} 
                        document={this.props.model} 
                        next={this.handleNext} 
                        showDownload={this.props.showDownload}
                    />
                </div>
            )
          case 2:
            return (
                <div style={{textAlign: 'center'}}>
                    <h2>Payment Complete</h2>
                    <CheckCircleIcon className="stepper-payment-complete" />
                    <p style={{color: '#565656'}}>You now have access to download and transfer this model.</p>
                </div>
            )
          default:
            return 'Unknown step';
        }
    }

    handleNext=(e)=>{
        if (this.state.activeStep<this.state.steps.length-1){
            this.setState({activeStep: (this.state.activeStep+1)})
        }else{
            this.setState({showPaywall: false});
        }
    }

    handleBack=(e)=>{
        if (this.state.activeStep>0){
            this.setState({activeStep: (this.state.activeStep-1)})
        }
    } 

    render(){
        const {billingDetails} = this.state;
        const billingEmpty = billingDetails.phone=="" || billingDetails.name=="" || billingDetails.email=="";
        return (
            <div>
                <Dialog onClose={()=>this.setState({showPaywall: false})} aria-labelledby="simple-dialog-title" open={this.state.showPaywall}>
                    <Stepper activeStep={this.state.activeStep}>
                        {this.state.steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {this.getStepContent(this.state.activeStep)}
                    <div className="modal-stepper-action-cont">
                        {this.state.activeStep!=(this.state.steps.length-1)&&<Button disabled={this.state.activeStep === 0} onClick={this.handleBack}>
                            Back
                        </Button>}
                        {this.state.activeStep!=1&&<Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                            disabled={billingEmpty}
                        >
                            {this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>}
                    </div>
                </Dialog>
                {!this.props.showPayBtn&&<Button variant='contained' color='primary' size="large" onClick={()=>this.initializePayment()}>Purchase Model ${this.props.price}</Button>}
            </div>
        )
    }
}

export default PayCard;