//external imports
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';

//material UI imports
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

//local imports
import './CardImageStyles.scss';
import CreateImg from '../../images/add-back.png';
import StripesImg from '../../images/stripes-light.png';

const styles = theme => ({
  badge: {
    top: '50%',
    right: -3,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
  card: {
    width: '100%',
    maxWidth: 350,
    minWidth: 250,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#189be5',
  },
});

const StyledRating = withStyles({
  iconFilled: {
    color: '#009be5',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

class CardImage extends React.Component {

  getImage() {
    if (this.props.create){
      return CreateImg;
    }else if (this.props.image) {
      return this.props.image
    }else{
      return StripesImg;
    }
  }

  async getIdToken() {
    await null;
    return localStorage.getItem('access_token');
  }

  renderHeader(classes) {
    if (!this.props.create){
      const badgeColor = this.props.model.verified ? '#009be5' : 'rgba(0,0,0,.2)'
      return(
        <CardHeader
          action={this.props.menu}
          className="basic-card-header"
          title={(this.props.title) ? <div className="trunc-elip" style={{maxWidth: '180px'}}>{this.props.title}</div> : '-'}
          // subheader={(this.props.date) ? moment(this.props.date).format('MMM Do, YY HH:mm') : 'N/A'}
          subheader={(this.props.publisher) ? <div className="trunc-elip" style={{maxWidth: '180px'}}>{this.props.publisher}</div> : '-'}
          action={
            <IconButton aria-label="settings" onClick={(e)=>this.props.dialogWindow(e)}>
              <VerifiedUserIcon style={{color: badgeColor}}/>
            </IconButton>
          }
        />
      )
    }
  }

  renderActions(classes) {
    if (!this.props.create) {
      const {rating, ratingCount, downloads} = this.props;
      const {verified, price}                = this.props.model;
      const ratingNum                        = rating ? rating : 0;
      const paid                             = (price && price>0) ? `$${price}` : 'Free';

      return (
        <CardActions className={classes.actions} disableactionspacing="true">
          <div className="ci-ref-actions">
            <div className="ci-ref-icon ci-ref-actions">
              <div className="ci-ref-icon ci-center">
                <StyledRating
                  readOnly
                  name="simple-controlled"
                  value={ratingNum}
                  precision={0.5}
                  size="small"
                />
                <span style={{fontSize: '.7em'}}>{ratingCount}</span>
              </div>
           </div>
            <div className="ci-ref-icon ci-icon-end ci-dld-icon trunc-elip">
              <div><GetAppIcon /></div>
              <span style={{fontSize: '.8em'}}>{downloads}</span>
            </div>
            <div className="ci-ref-icon ci-icon-end">
              {paid}
           </div>
         </div>
        </CardActions>
      )
    }else{
      return (
        <CardActions className={classes.actions + ' create-project-card-txt'}>
          <div>Add Project</div>
        </CardActions>
      )
    }
  }

  render() {
    const { classes, id } = this.props;
    const mediaUrl = this.getImage();

    return (
      <Card id={id} className={classes.card + ' ' + this.props.className} onClick={()=>window.location = '/model/'+id}>
        {this.renderHeader(classes)}
        <CardMedia
          className={classes.media}
          image={mediaUrl}
          style={{paddingTop: '140px'}}
        />
        {this.renderActions(classes)}
      </Card>
    );
  }
}

CardImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardImage);
