import lock from 'auth0-lock';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import {initializeReactGA} from './Util/ga_event';

const AUTH0_PK = process.env.REACT_APP_AUTH0_PK;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_LOGO = process.env.REACT_APP_AUTH0_LOGO;

export default class Auth0 {
    accessToken;
    idToken;
    expiresAt;
    tokenRenewalTimeout;

    constructor () {
      this.lock = new lock(
        AUTH0_PK,
        AUTH0_DOMAIN,
        {
          configurationBaseUrl: 'https://cdn.auth0.com',
          allowSignUp: false,
          auth: {
            redirectUrl: `${window.location.protocol}//${window.location.hostname}/callback`,
            responseType: 'token',
            params: {
              scope: 'openid email profile offline_access'
            }
          },
          languageDictionary: {
            title: "Flexible Vision"
          },
          theme: {
            logo: AUTH0_LOGO,
            primaryColor: '#009be5'
          }
        }
      );


      this.lock.on('authenticated', this._doAuthentication.bind(this));
      this.scheduleRenewal();
    }

    handleAuthentication() {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        } else if (err) {
          console.log(err);
          alert(`Error: ${err.error}. Check the console for further details.`);
        }
      });
    }

    _doAuthentication (authResult) {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setToken(authResult);
          // setTimeout(()=>{window.location.replace("/")}, 2000); 
        } else {
          console.log('Could not authenticate');
          alert(`Error: Could not authenticate. Check the console for further details.`);
        }
    }

    login = () => {
      this.lock.show();
    }

    loggedIn = () => {
        const token = this.getToken();
        return !this.isTokenExpired(token);
    }

    setToken (authResult) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('access_token', authResult.accessToken);

        const tokenDetails = decode(authResult.idToken);
        localStorage.setItem('avatar', tokenDetails.picture);
        localStorage.setItem('user_id', tokenDetails.sub);
        localStorage.setItem('nickname', tokenDetails.nickname);
      
        let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        console.log("authentication expires at " + expiresAt);
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        this.expiresAt = expiresAt;
        initializeReactGA(tokenDetails.sub);
    }

    getToken = () => {
        return localStorage.getItem('id_token');
    }

    isAuthenticated = () => {
      // Check whether the current time is past the
      // access token's expiry time
      let expiresAt = this.expiresAt;
      return new Date().getTime() < expiresAt;

    }

    isTokenExpired(token) {
      try {
          const decoded = decode(token);
          if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
              return true;
          }
          else
              return false;
      }
      catch (err) {
          return true;
      }
    }

    scheduleRenewal() {
      // let expiresAt = this.expiresAt;
      // const timeout = expiresAt - Date.now();

      // console.log('EXPIRES AT   ', expiresAt);
      // if (timeout > 0) {
      //   this.tokenRenewalTimeout = setTimeout(() => {
      //     console.log('INSIDE SET TIMEOUT RENEWAL')
      //     this.renewSession();
      //   }, timeoutisTokenExpired);
      // }

      const id_token = this.getToken();
      if (!id_token) return;
      if (this.isTokenExpired(id_token)){
        this.renewSession();
      }
    }

    renewSession() {
      this.lock.checkSession({responseType: 'token id_token'}, (err, authResult) => {
         if (authResult && authResult.accessToken && authResult.idToken) {
           this.setToken(authResult);
         } else if (err) {
           this.logout();
           console.log(err);
         }
      });
    }

    logout = (auth) => {
        console.log('logged out');
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('avatar');
        localStorage.removeItem('nickname');
        Cookies.remove('id_token');
        Cookies.remove('access_token');

        this.clearCookie('id_token', '.flexiblevision.com', '/');
        this.clearCookie('access_token', '.flexiblevision.com', '/');
        // Remove tokens and expiry time
        this.accessToken = null;
        this.idToken = null;
        this.expiresAt = 0;
      
        // Remove isLoggedIn flag from localStorage
        localStorage.removeItem('isLoggedIn');
        // this.lock.logout();
        setTimeout(()=>{window.location.replace("/")}, 1000); 
    }

    clearCookie(name, domain, path){
      var domain = domain || document.domain;
      var path = path || "/";
      document.cookie = name + "=; expires=" + +new Date + "; domain=" + domain + "; path=" + path;
    }
}
