import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../../components/Login';
import history from '../../Util/history';
import Callback from '../../Util/Callback';
import SingleModel from '../../components/SingleModel';
import Profile from '../../components/Profile/Profile';
import ModelList from '../../components/ModelList';
import ConnectCallback from '../../components/ConnectCallback';


class Main extends Component {
    render () {
        return (
            <main className='container-fluid' id="main">
                <Switch history={history} >

                    <Route path='/model/:modelId' render={props => <SingleModel {...props} auth0={this.props.auth0} />} />
                    <Route path='/user/:userId' render={props => <Profile {...props} auth0={this.props.auth0} />} />
                    <Route path="/login" render={props => <Login {...props} auth0={this.props.auth0}/>} />
                    <Route path="/callback" render={props => <Callback {...props} />}/>
                    <Route path="/connect_callback" render={props => <ConnectCallback {...props} />}/>
                    <Route path='/' render={props => <ModelList {...props} /> } />
                
                </Switch>
            </main>
        );
    }
}
export default Main;
