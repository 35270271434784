import * as types from '../constants/ProgramTypes';
import _ from 'lodash';

export default function trainReducer(state = null, action) {
    switch (action.type) {
        case types.UPDATE_PROGRAM:
            const program = _.cloneDeep(action.payload);
            return program;

        default:
            return state;
    }
}
        