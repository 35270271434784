import {CAM_INIT, CAM_GET_LIST, CAM_GET_PHOTO, CAM_GET_STREAM, CAM_CLEAR_STREAM, GET_CAPTURES} from '../actions/cameras';

// reducer
export default function cameraReducer(state = CAM_INIT, props) {
    let newState = Object.assign({}, state); // only works because state is a flat object (object.assign doesn't copy deep)
    switch (props.type) {
        case CAM_GET_LIST:
          newState.allCameras = props.allCameras;
          if (props.allCameras.length == 0) newState.activeCamera = null;
          else if (!state.activeCamera) newState.activeCamera = props.allCameras[0];
          return newState;
        case CAM_GET_PHOTO:
          newState.camBlob = props.camBlob;
          return newState;
        case CAM_GET_STREAM:
          newState.stream = props.stream;
          return newState;
        case CAM_CLEAR_STREAM:
          if (newState.stream) {
            var tracks = newState.stream.getVideoTracks();
            for(var t in tracks) {
              tracks[t].stop();
            }
          }
          newState.stream = null;
          return newState;

        case GET_CAPTURES:
          return {...state, captures: props.payload.captures}
        default:
            return state;
    }
}
